.dashboard-topbar {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #9f8754;
  box-shadow: -10px 5px 15px 0px #896c2c inset;
  padding: 0.1rem 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
}
.dashboard-topbar .hamburger {
  display: none;
  font-size: 1.5rem;
}
.dashboard-topbar ul > .menu-items > div span {
  margin-inline: 0.3rem;
  font-size: 0.9rem;
}
.dashboard-topbar > div ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-topbar > div ul li {
  margin: 0 0.5rem;
}
.dashboard-topbar ul > .menu-items > div svg {
  font-weight: 500;
  width: 0.8rem;
  height: 0.8rem;
}
.dashboard-topbar > div ul li .user_dp {
  width: 30px;
  border-radius: 6px;
}
.dashboard-topbar > div ul li .user_dp img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
}
.dashboard-topbar > div ul > .menu-items {
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin: 0 1.2rem;
  cursor: pointer;
}
.dashboard-topbar > div ul > .menu-items ul {
  background-color: #2b2b2b;
  display: inline-block;
  position: absolute;
  top: 115%;
  right: -7%;
  padding: 0.7rem;
  width: 200px;
  z-index: 1000;
  transition: all 0.3s linear;
}

/* .dashboard-topbar>div ul>.menu-items:hover .dropdown.active{
    display: inline-block;
} */
.dashboard-topbar > div ul > .menu-items ul li {
  color: #fff;
  list-style: none;
  margin: 0.3rem 0;
  padding: 0.2rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ffffffc5;
}
.dashboard-topbar > div ul > .menu-items ul li svg {
  font-size: 0.95rem;
}
.dashboard-topbar > div ul > .menu-items ul li a {
  color: #fff;
  font-size: 0.85rem;
  margin-left: 0.5rem;
}
.dashboard-topbar > div ul > .menu-items div {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-topbar .logo-imgBx {
  display: flex;
}
.dashboard-topbar .logo-imgBx svg {
  font-size: 1.8rem;
  margin-right: 0.3rem;
}
.dashboard-topbar .logo-imgBx h4 {
  margin: 0%;
  font-size: 0.85rem;
  line-height: 0.8rem;
  color: #fff;
  text-transform: capitalize;
}
.toptime {
  display: block;
  position: relative;
  color: #edde93;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
}
@media screen and (max-width: 900px) {
  .dashboard-topbar .hamburger {
    display: block;
  }
}

/* Notification icons */
.notification-icon {
  position: relative;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red; /* Choose your desired background color */
  color: white; /* Choose your desired text color */
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}

.notification-center-none {
  display: none;
}
.notification-center-block {
  display: block;
  z-index: 500;
  top: 20;
  background-color: red;
}
