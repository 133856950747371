.rinding {
  display: flex;

  height: auto;
  background: #0b0603;
  /* background: #d642428a; */
  min-height: 500px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.header-white {
  background: linear-gradient(to right, grey, white) !important;
  color: #000000 !important;
}
.header-pink {
  background-color: #faaca8;
  background-image: linear-gradient(
    to right,
    #faaca8 0%,
    #ddd6f3 100%
  ) !important;
}
.header-skyBlue {
  /* background: linear-gradient(to right, #3fb896, white) !important; */
  background-image: linear-gradient(
    to right,
    #fff1eb 0%,
    #ace0f9 100%
  ) !important;
}
.header-grassy {
  /* background-color: #d79922 !important; */
  background-image: linear-gradient(
    to right,
    #c1dfc4 0%,
    #deecdd 100%
  ) !important;
  color: #000000 !important;
}
.header-roseate {
  background-color: #d9afd9;
  background-image: linear-gradient(
    to right,
    #d9afd9 0%,
    #97d9e1 100%
  ) !important;
  color: #000000 !important;
}
.header-gold {
  /* background-color: #106466 !important; */
  background-image: linear-gradient(
    to right,
    #fdfcfb 0%,
    #e2d1c3 100%
  ) !important;
  color: black !important;
}
.header-orange {
  /* background-color: #106466 !important; */
  background-image: linear-gradient(
    to right,
    #e6b980 0%,
    #eacda3 100%
  ) !important;
  color: #000000 !important;
}
.card-images {
  display: flex !important;
  flex-wrap: wrap;
}
.my-card {
  width: 23rem !important;
}
.my-card img {
  width: 100%;
}

.rinding .card-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.rinding .card-images .my-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 30.5%;
  height: 14rem;
  position: relative;
  border-radius: 5px;
  background-color: aliceblue;
  margin: 20px;
}
.rinding .card-images .my-card img {
  position: relative;
  width: 100%;
  height: 100%;
}
.rinding .card-images .my-card .title {
  position: absolute;
  color: white;
  left: 0;
  bottom: 0;
  padding: 4px 43px 7px 30px;
  background: #000000b3;
  border-top-right-radius: 10px;
}

.events {
  /* background-color: black; */
  background-image: linear-gradient(
    to right,
    #fdfcfb 0%,
    #e2d1c3 100%
  ) !important;
  width: 100%;
  min-height: 500px;
  overflow: hidden;
  position: relative;

  display: flex;
  justify-content: space-between;

  /* border: 1px solid white; */
  flex-wrap: wrap;
  padding-inline: 6%;
}
.tab-container {
  width: 40rem !important;
}
.calender_div {
  width: 30rem;
}

.events .tab-container {
  width: 63.076923077%;
  float: left;
}
.events .tab-container .tab-nav {
  border-radius: 5px 5px 5px 0;
  min-width: 106px;
  text-align: center;
  background: #302924;
  display: none;
  color: #bebb9e;
  font-size: 0.9em;
  max-width: 191px;
  height: 47px;
  line-height: 47px;
  cursor: pointer;
}
.events .tab-container .tabs {
  overflow: hidden;
  padding: 0;
  margin-bottom: 0;
}
.events .tab-container .tabs li {
  float: left;
  list-style: none;
}

.events .tab-container .tabs li a {
  line-height: 20px;
  border-radius: 5px 5px 0 0;
  min-width: 106px;
  padding: 15px 16px 10px;
  text-align: center;
  margin-right: 3px;
  background: #bebb9e;
  display: block;
  color: #302924;
  text-decoration: none;
  font-size: 0.9em;
  font-family: var(--main-font);
}
.events .tab-container .tabs li a:hover,
.selected {
  background-color: #302924 !important;
  color: #bebb9e !important;
}
.tab-content-holder {
  background-color: #302924;
  padding: 27px;
  border-radius: 0 5px 5px 0;
  min-height: 275px;
}
.tab-content {
  min-height: 270px;
}
.tab-content h3 {
  font-size: 0.8em;
  color: #ffffff;
  font-weight: bold;
}
.tab-content p {
  font-size: 0.8em;
  padding: 14px 0;
  text-align: justify;
}
.tab-content p a {
  color: #fff000;
  text-decoration: underline;
}
.tab-image {
  border: 9px #26201c solid;
  float: left;
  margin-right: 13px;
}
.eventallreadmore {
  bottom: 0px;
  text-decoration: none;
  color: #ffffff;
  text-align: right;
  float: right;
}

/* .my-card :hover {
  border: 3px solid #bebb9e;
  transition: 200ms ease-in-out;
} */
.my-card img:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border-radius: 10px;

  transition: 100ms ease-in;
  cursor: pointer;
}
.my-card img {
  border-radius: 5px;
}
.events-desc p {
  font-size: 1.2rem;
  color: white;
}
.calender_div {
  width: 30rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
