.lesson-container {
  /* background-color: red; */
  width: 100%;
  /* height: 100vh; */
  padding: 1rem;
  /* gap: 0.5rem; */
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}
.lesson-track-info{
    display: flex;
    /* flex-direction: column; */


}
.lesson-course-section{
    top: 0;
    position: sticky;
    height:100%;
    /* background: red; */
    width: 60%;
    /* margin: 1rem; */
    padding-top:1rem ;
}

.lesson-track {
  /* background-color: white; */
  height: 10rem;
  width: 15%;
  justify-content: center;
  display: flex;
  /* padding: 1rem; */
  flex-direction: column;
  align-items: center;
  /* gap: 0.5rem; */
}
.lesson-sub-container{
    display: flex;
  flex-direction: column;
  width: 40%;
}
.lesson-info {
  /* background-color: purple; */
  height: 10rem;
  width: 80%;
  padding: 1rem;
}
.track__circle {
  width: 40px;
  height: 40px;
  /* background: rgb(47, 232, 47); */
  background:#f7be16;
  border-radius: 50%;
  /* color: rgb(159, 158, 158); */
}
.track__line {
  width: 4px;
  height: 200px;
  border-radius: 10px;
  background:#f7be16;
  /* background:#4caf50; */
    margin: 2px;
}
.lesson-course-section-md{
    display: none;

    height: 10rem;
    background: red;
    width: 100%;
}
.lesson-course-imag{
    object-fit: cover;
    height: 20rem;
    /* width: 25rem; */
    width: 100%;
    border-radius: 10px;
}
.center-p-span{
    display: flex;
    align-items: center;
}
.lesson-schedule-info{
    display: flex;
    gap: 2.65rem;
}
.lesson-horse-trainer-info{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;

}
@media only screen and (max-width:680px){
    .lesson-container{
        flex-direction: column;
        gap:0.3rem;
    }
    .lesson-course-section{
        display: none;
    }
    .lesson-course-section-md{
        display: block;
    }
    .lesson-sub-container{
        width: 100%;
    }
    .lesson-schedule-info{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .lesson-horse-trainer-info{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        
    }
}