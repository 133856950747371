.trainerAgreeSection{
    padding-block: 4%;
     width: 90%;
    margin: auto;
}

.tAgreeHead{
  margin: 5% 2% 2% 0%;
}
.tAgreeUl {
padding: 5px 50px;
text-align: justify;
}
.tAgreeUl ol li {
padding: 6px 5px ;
text-align: justify;
}
.tAgreeUl ul  {
padding: 6px 30px ;
text-align: justify;
list-style-type:disc;
}
.trainerAgreement p {
   font-size: 12.5px;
   display: flex;
   align-items: center;
   font-weight: bold;
   line-height: none !important;
   
 }
 .tAgreeFoot{
  margin-block: 2rem;
 }