.week-view{
    width: 90%;
    margin: 1rem auto;
  }
.week-view .hour-events{
    background-color:#7c5e1e47;
    margin:0.6rem;
    padding-bottom: 0px;
}
.week-view .week-date .event-column{
    background-color: transparent !important;
}
.week-view .week-date .event-column .event{
    font-size: 0.9rem;
}
.my_class table{
    width: 100%;
}
.my_class table th{
    color: #fff;
}
.event-list td {
    width: 13%;
    min-height: 3.5rem;
    padding-left: 15px;
    border-inline: 1px solid #7e5f272b;
    cursor: pointer;
}
.event{
    position : relative
}
.event .event-title{
    position: relative;
}
.event .event-title .event-details{
    position: absolute;
    display:none;
    width: max-content;
    padding: 0.7rem;
    background: #FFF;
    border-radius: 0.2rem;
    z-index: 200;
    box-shadow: 0px 0px 0px 3px rgba(168, 168, 168, 0.35);
    /* opacity: 0; */
    transition: all 0.3s linear;
}
.event .event-title:hover .event-details{
    opacity:1;
    display:block;
}