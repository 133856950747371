.imgslider {
  position: relative;
}

.slick-slide img {
  margin: auto;
  width: 100%;
  height: 70vh;
  border-radius: 2px;
}
.scroll-bar::-webkit-scrollbar {
  display: none;
}
.tag {
  background-color: rgba(163, 163, 163, 0.293);
  width: 20rem;
  height: 8vh;
  text-align: center;
  text-shadow: 2px 2px 6px rgb(196, 221, 219);
  position: absolute;
  z-index: 1;
  margin-top: 2rem;
  margin-left: 2rem;
  font-size: 1rem;
  border-radius: 10px;
}
.tag h2 {
  color: #302924;
}
.img-list-carousel {
  display: flex;
  width: 60vw;
  justify-content: space-between;
  border: 2px dashed burlywood;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  transition: 80ms ease-in-out;
}