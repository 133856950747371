@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");


.inputparts-checkIn { 

    padding: 10px;
    margin-left: 12px;
}


.password-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888;
  }