.header {
  background-color: black;
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}

.header-logo {
  width: 80px;
  height: 80px;
}

.header-logo a img {
  width: 90%;
  height: 90%;
}

.header-white {
  background: linear-gradient(to right, grey, white) !important;
  color: #000000 !important;
}

.header-pink {
  background-color: #faaca8;
  background-image: linear-gradient(19deg, #faaca8 0%, #ddd6f3 100%) !important;
}

.header-skyBlue {
  background-image: linear-gradient(
    to top,
    #fff1eb 0%,
    #ace0f9 100%
  ) !important;
}

.header-grassy {
  background-image: linear-gradient(
    to top,
    #c1dfc4 0%,
    #deecdd 100%
  ) !important;
  color: #000000 !important;
}

.header-roseate {
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%) !important;
  color: #000000 !important;
}

.header-gold {
  background-image: linear-gradient(
    135deg,
    #fdfcfb 0%,
    #e2d1c3 100%
  ) !important;
  color: black !important;
}

.header-orange {
  background-image: linear-gradient(
    to top,
    #e6b980 0%,
    #eacda3 100%
  ) !important;
  color: #000000 !important;
}

/*navgation*/
.main-menu {
  width: 100%;
  max-width: 1171px;
}

.navx {
  list-style: none;
  zoom: 1;
  border-top: 5px solid #bebb9e;
  justify-content: space-around;
  background: #000000;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  display: flex;
  padding: 0px !important;
}

.navx-light-theme {
  background: #ffffff !important;
}

/* .dropbtn {
  padding: 37px 20px 34px;
  padding: 10px;
  text-transform: uppercase;
  border-right: 1px solid #221d19;
  border-left: 1px solid #4c4744;
  text-decoration: none;
  color: #bebb9e;
  font-size: 20px;
  display: flex;
  justify-content: center;
  height: 70px;
  align-items: center;
} */

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* top: 97px; */
  background-color: #302924;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.dropdown-content a {
  display: block;
  background: #302924;
  position: relative;
  z-index: 100;
  padding: 15px;
  /* border-top: 1px solid #221d19;
  border-bottom: 1px solid #4c4744; */
  text-align: left;
  text-transform: uppercase;
  /* border-right: 1px solid #221d19;
  border-left: 1px solid #4c4744; */
  text-decoration: none;
  color: #bebb9e;
  font-size: 18px;
}

.dropdown-content a:hover {
  background: #302924;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.SideBarbtn {
  display: none;
}

.SideBarbtnClose {
  display: none;
}

/* .social-icon li:hover {
  transform: scale(1.002);
  transition: 100ms;
} */
@media only screen and (max-width: 1086px) {
  .navx {
    /* flex-wrap: wrap;
    width: 200px;
    position: absolute;
    z-index: 2;
    top: 0;
    padding: 0px; */
    display: none;
  }

  .SideBarbtn {
    position: absolute;
    top: 200px;
    display: block;
    z-index: 5000;
  }

  .SideBarbtnClose {
    position: absolute;
    top: 0px;
    left: 170px;
    display: block;
    z-index: 5000;
  }

  .main-nav {
    padding: 5px;
    text-transform: uppercase;
    height: 20px !important;
    text-decoration: none;
    color: #bebb9e;
    font-size: 20px;
  }

  .ResponsiveNav li {
    margin-block: 20px;
  }

  .dropbtn {
    padding: 10px;
  }

  .dropdown-content {
    left: 12rem;
  }
}

.ResponsiveNav {
  list-style: none;

  zoom: 1;
  border-top: 5px solid #bebb9e;
  justify-content: space-around;
  background: #302924;
  margin: 0;

  width: 200px;
  position: absolute;
  z-index: 2;
  top: 200px;
  padding: 0px;
}

.navx:after {
  clear: both;
}

.navx ul {
  list-style: none;
  width: 220px;
}

.main-nav {
  /* padding: 37px 0 34px; */
  padding: 10px;
  text-transform: uppercase;
  /* border-right: 1px solid #221d19;
  border-left: 1px solid #4c4744; */
  text-decoration: none;
  color: #bebb9e;
  font-size: 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navx a:hover {
  background: #bebb9e;
  color: #302924;
  transition: 300ms ease-in;
}

/* ------------------ */
.ResponsiveNav ul {
  list-style: none;
  width: 220px;
}

.ResponsiveNav a:hover {
  background: #bebb9e;
  color: #302924;
}

/* ---------------- */

.menucolor {
  color: #bebb9e;
}

.link {
  color: #ffffff;
  text-decoration: none;
}

.link:hover {
  color: #86acac;
  text-decoration: underline;
}

.subtitle {
  width: 153px;
  display: block;
  border-bottom: solid 1px #bebb9e;
  margin-top: 10px;
  margin-bottom: 10px;
}

.currentMenu {
  background: #bebb9e;
  color: #302924;
}

.navx li {
  position: relative;
}

.navx > li {
  float: left;
  text-align: center;
  /* background: rgba(60, 136, 27, 0.749); */
  width: 190px;
  /* height: 70px; */
}

.navx > li > .parent {
  /* background-image: url("images/downArrow.png"); */
  background-repeat: no-repeat;
  background-position: right;
}

/* .navx > li > a {
  display: block;
} */
.navx li ul {
  position: absolute;
  left: -9999px;
}

.navx > li.hover > ul {
  left: 0;
}

.navx li li.hover ul {
  left: 100%;
  top: 0;
}

.navx li li a {
  display: block;
  background: #302924;
  position: relative;
  z-index: 100;
  /* padding: 15px; */
  padding: 10px;
  border-top: 1px solid #221d19;
  border-bottom: 1px solid #4c4744;
  text-align: left;
}

.navx li li li a {
  background: #302924;
  z-index: 200;
  border-top: 1px solid #221d19;
}

.select-menu {
  display: none;
  border: none;
  background: #302924;
  outline: none;
  width: 100%;
  color: #bebb9e;
  text-transform: uppercase;
  font-size: 1em;
  padding: 20px;
}

.current-nav {
  position: absolute;
  height: 68px;
  z-index: 2;
  display: none;
  width: 100%;
  padding-left: 69px;
  text-transform: uppercase;
  line-height: 68px;
}

.main-nav-bgcolor {
  background-color: #bebb9e !important;
  color: #302924 !important;
}

.notranslate {
  color: #ace0f9 !important;
}

.header .MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
  border-color: #bebb9e !important;
}

.header .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #bebb9e !important;
}

.header .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #bebb9e !important;
}

@media only screen and (max-width: 600px) {
  .SideBarbtn > button {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: -6rem;
  }
  .SideBarbtn > button > i {
    font-size: 1.5rem;
  }
}
