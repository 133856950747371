.header-print,
.header-space,
.footer-print,
.footer-space {
    height: 100px;
}

.header-print {
    position: fixed;
    top: 0;
}

.footer-print {
    position: fixed;
    bottom: 0;
}



.header-space {
    display: block;
    text-align: center;
    border-bottom: 2px solid black;
    width: 100vw;
    height: 130px;
    margin-bottom: 20px;
}

.footer-space {
    /* margin-block: 20px;
    display: block;
    text-align: center;
    width: 100vw;
    height: 80px; */
    display: block;
    text-align: center;
    /* border-top: 2px solid black; */
    width: 100vw;
    height: 130px;
    margin-bottom: 20px;
}


.amount-tbody {
    width: 100vw;
}

.amount-detail {
    width: 18rem;
    float: right;
    margin-block: 30px;
    padding: 10px;
    line-height: 5px;
    right: 0;
    line-height: 1.6rem;
}