.register-page {
  height: 100vh;
  /* width: 100vw; */
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.register-container {
  background-color: #fefdfc;
  padding: 30px 40px;
  width: fit-content;
  margin: auto;
}

.register-module-container {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  padding-top: 6rem;
}

.register-right-container-mobile {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  /* .register-right-container-mobile {
      display: block;
      padding: 50px 20px;
      scroll-behavior: auto;
    }
  
    .login-form {
      margin-top: 40px;
    }
  
    .login-section {
      display: none;
    }
  
    .register-container {
      padding: 10px 40px;
      width: fit-content;
    } */

  .register-module-container {
    padding: 0rem 3rem;
    height: 100vh;
  }
  .register-page {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .register-module-container {
    padding: 5rem 3rem;
  }
  .register-page {
    height: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .register-module-container {
    padding-top: 3rem;
  }
  .register-page {
    height: auto;
  }

  .login-right-container {
    padding-bottom: 3rem;
  }
}
