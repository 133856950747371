.Img_div {
  width: 100%;
  height: 75vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.Img_text_div {
  width: 45rem;

  background-color: rgba(41, 41, 41, 0.784);
  color: white;
  font-family: var(--secondary-main-font);
  font-size: 1.5rem;
  padding: 1rem;
}
@media screen and (width<=1000px) {
  .Img_div{
    height: 40vh;
  }
  
}
