.carousel-root {
    height: 70vh; 
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .carousel-slider{
    width: 100%;
    height: 70vh;
    margin: auto;
    position: relative;
    /* object-fit: contain; */
    /* background-color: aqua; */
    /* border: 2px solid tomato; */
  }
  .carousel .slide img{
    height: 100vh;
    /* border: 2px solid green; */
  }
  
.carousel-slider img {   
    height: 100vh ;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    /* object-fit: cover; */
  }

  .carousel .carousel-status{
    display: none;
  }