.ridedetails__container {
  display: flex;
  align-items: flex-start;
  /* padding: 2rem 20rem; */
  max-width: 85rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  padding: 0rem 1rem;
  gap: 2rem;
}
hr {
  color: #666666;
}

.ridedetails__main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
}

.ridedetails__main h1 {
  word-spacing: 0.3rem;
}

.ridedetails__section1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #666666;
}
.ridedetails__section1 > img {
  width: 100%;
  height: auto;
}

.ridedetails__section1 > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.ridedetails__section1 > div > span {
  color: black;
  font-size: 1.5rem;
}

.ridedetails__section1 > p {
  width: 100%;
  margin: 7px 0 6px 0;
  text-align: justify;
}

.ridedetails__section2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ridedetails__section2 > p {
  color: #666666;
  word-spacing: 0.2rem;
  text-align: justify;
}

.ridedetails__section3 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  cursor: pointer;
}

.ridedetails__section3 > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.ridedetails__section3 > div > span {
  font-size: 2rem;
}

.detail__open {
  transform: rotate(180deg);
}
.detail__show {
  display: block;
  color: #666666;
  word-spacing: 0.4rem;
  text-align: justify;
}
.detail__hide {
  display: none;
}

.ridedetails__section4 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  cursor: pointer;
}

.ridedetails__section4 > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.ridedetails__section4 > div > span {
  font-size: 2rem;
}

.ridedetails__checkout__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4.5rem 4rem;
}

.ridedetails__checkout {
  display: flex;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
  flex-direction: column;
  gap: 2rem;
}

.checkout__section {
  width: 20rem;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 2rem;
  align-items: flex-start;
  color: #666666;
  gap: 1rem;
}

.checkout__section > span {
  color: #000000;
  font-weight: 600;
  font-size: 1.125rem;
}

.checkOut__calendarSection {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.checkout__booking__details {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: #666666;
}

.checkout__booking__cart1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.3rem;
}

.checkout__booking__cart2 > button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px;
  border: none;
  background-color: #879ffd;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  height: auto;
  cursor: pointer;
}

.checkout__booking__cart2 {
  display: flex;
  align-items: center;
  width: 6rem;
  justify-content: space-between;
  /* gap: 0.5rem; */
}

.checkout__inActive {
  color: #fff !important;
  background-color: rgb(231, 225, 225) !important;
  cursor: not-allowed !important;
}

.checkout__total {
  display: flex;
  width: 100%;
}

.checkout__total > span {
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-align: right;
  margin-bottom: 20px;
  color: #000000;
}

.checkout__buyNow {
  /* padding: 2rem 1rem; */
  width: 100%;
  margin-bottom: 1rem;
}

.checkout__buyNow  {
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  min-width: 100px;
  height: 45px;
  line-height: 45px;
  padding: 0 20px;
  background-color: #708bf8;
  color: #fff;
  width: 100%;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.checkout__buyNow :hover {
  opacity: 0.9;
}
.slots_clock{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50rem;
  /* width: 50%; */
  gap:0.5rem;
  overflow-x: auto;
  padding: 0.4rem;

}
.slot-active{
  background: rgb(62, 61, 51); 
  color: white;

}
.slot-clock-div{
  border: 1px solid black;
  border-radius: 1rem;
  padding: 0.5rem;
  /* width: 5rem; */
  min-width: 6rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
 
}
/* width */
.slots_clock::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}

/* Track */
.slots_clock::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.slots_clock::-webkit-scrollbar-thumb {
  background: rgb(190, 187, 158); 
  border-radius: 10px;
}

/* Handle on hover */
.slots_clock::-webkit-scrollbar-thumb:hover {
  background: rgb(190, 187, 158); 
}

@media only screen and (max-width: 980px) {
  .ridedetails__container {
    flex-direction: column;
  }
  .ridedetails__checkout__container {
    padding: 0;
  }
  .ridedetails__checkout {
    flex-direction: row;
  }
}

@media only screen and (max-width: 600px) {
  .ridedetails__checkout {
    flex-direction: column;
  }
}
