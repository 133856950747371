.register-page {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.register-container {
  background-color: #fefdfc;
  padding: 30px 40px;
  width: fit-content;
  margin: auto;
}

.register-module-container {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  padding-top: 6rem;
}

.register-right-container-mobile {
  display: none;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .register-module-container {
    padding-top: 3rem;
    height: 100vh;
  }
  .register-page {
    height: auto;
  }
}
