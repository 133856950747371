.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
    padding: 7.5px 4px 7.5px 5px;
    color: black !important;
    font-weight: 300px !important;
    font-size: 0.85rem;
}

.pdfCard:hover {
    text-decoration: underline;
    color: #9f8754;
    transition: all 0.3s linear;
}



.owneregistrationh3 h3::before {
    font-family: "Poppins", sans-serif;
    background-color: black;
    color: #f2f2f2;
    padding: 4px 8px;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 8px;
    font-size: 15px;
}

.registration-information h3::before {
    content: "1";
    padding-inline: 10px;
}

.owner-information h3::before {
    content: "2";
    padding-inline: 10px;
}

.owner-contact-information h3::before {
    content: "3";
}

.owner-upload-documents h3::before {
    content: "4";
}



.owner-remark h3::before {
    content: "5";
}
.owner-tandc h3::before {
    content: "6";
}