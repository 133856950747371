.dashboardlayout {
  width: 100%;
}

.main-content {
  position: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 2rem;
}
.dashboard {
  display: flex;
  width: 100%;
  /* border: 1px solid red ; */
}
.dashboard .dashboard-left-container {
  width: 75%;
  padding: 1rem;
}
.dashboard .dashboard-right-container {
  background-color: rgb(248, 244, 240);
  width: 25%;
  padding: 0.5rem;
  height: 100%;
}

.footer2 {
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 9999999999;
}
.footer2 .sub-footer {
  width: 100%;
  background: #333;
  text-align: center;
  padding: 0.5rem 0;
  color: #fff;
}
.footer2 .sub-footer.footer2-container {
  margin-inline: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.footer2 .sub-footer p {
  margin-bottom: 0;
  font-size: 12px;
  color: #fff;
}

@media only screen and (max-width: 1025px) {
  .footer2 {
    display: none;
  }
  .dashboard {
    flex-direction: column;
  }
  .dashboard .dashboard-left-container {
    width: 100%;
  }
  .dashboard .dashboard-right-container {
    width: 100%;
  }
  .main-content {
    margin-bottom: 2rem;
  }
}
