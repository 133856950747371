.mainContainerOfHorseProfile .contentsOfHorseProfile {

    font-size: 1.5rem;
    padding: 10px;

    /* background-color: #e3d9c1; */

}
.horse_details{
    margin-block: 30px;
}
.horse_data{
    width: 90%;
}
.horse_data  p{
    line-height: 2rem;
}
.ContainerOfHorseProfile .buttons {
    width: 100%;
}

.ContainerOfHorseProfile .buttons button {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1rem;
    padding: 1rem;
    margin-bottom: 10px;
    text-align: left;
    background-color: #e3d9c1;
    border: none;
    cursor: pointer;
}