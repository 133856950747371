.chart {
  width: 100%;
  margin: 4px;
  border: 1px solid #7c5e1e;
  background: white;
  border-radius: 8px;
  height: 22rem;
}

.chartTitle {
  margin-bottom: 20px;
  border-bottom: 1px solid gray;
  padding-bottom: 1rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.Radar-chart {
  width: 100%;
  margin: 20px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  background: white;
  border-radius: 8px;
}

.pie-chart {
  width: 60%;
  margin: 20px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  background: white;
  border-radius: 8px;
}

.pie-chart_info {
  font-size: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .chart {
    margin: 0px;
  }
  .Radar-chart {
    margin: 0px;
  }
  .pie-chart {
    width: 100%;
    margin: 0px;
  }
}

/* Medium devices (landscape tablets, 768px) */
@media only screen and (min-width: 768px) {
  .pie-chart {
    width: 100%;
  }
}

/* Large devices (laptops/desktops) */
@media only screen and (min-width: 992px) {
  .pie-chart {
    width: 60%;
  }
}
