.rideView__container {
  display: flex;
  max-width: 100%;
  min-height: 800px;
  /* margin: 0 auto; */
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.rideView__header {
  display: flex;
  gap: 2rem;
  margin: 0 auto;
}

.rideView__right__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.rideView__right__topSection {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.rideView__right__info1 {
  display: flex;
  border: 1px solid rgba(229, 231, 235);
  align-items: center;
  border-radius: 0.5rem;
  justify-content: space-between;
  /* width: 40rem; */
  width: 100%;
}

.rideView__inputs {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.rideView__inputs > span {
  font-size: 1.2rem;
  display: flex;
  margin-left: 0.5rem;
}

.rideView__inputs > input {
  border: none;
  outline: none;
  padding: 0.8rem;
  font-size: 1rem;
  width: 100%;
}

.activeInput {
  border-right: 1px solid rgba(229, 231, 235) !important;
  border-left: 1px solid rgba(229, 231, 235) !important;
}

.rideView__dates {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.8rem;
  padding-left: 2rem;
}

.rideView__dates:hover {
  background-color: rgb(218, 211, 211);
}

.rideView__dates > span {
  font-size: 0.9rem;
  display: flex;
  cursor: pointer;
}

.rideView__dates__container {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  gap: 0.8rem;
}

.rideView__dates__container > button {
  display: flex;
  padding: 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  border: none;
  width: 6rem;
  align-items: center;
  justify-content: space-around;
  font-size: 1rem;
}

.rideView__dates__container > button > span {
  display: flex;
}

.rideView__right__info2__container {
  position: relative;
}

.rideView__right__info2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.rideView__right__info2 > span {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.rideView__right__info2 > button {
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 8rem;
}

.rideView__right__filter {
  width: 12rem;
  height: 12rem;
  position: absolute;
  background-color: white;
  color: black;
  border: 1px solid rgba(229, 231, 235);
  border-radius: 0.5rem;
  right: 0;
  top: 3rem;
}

.rideView__right__filter > ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1rem;
  gap: 1rem;
  text-align: center;
}

/* Card section css starts from here */

.rideView__right__bottomSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.25rem;
}

.ridesOptionCard__header {
  max-width: 287px;
  width: 100%;
  display: flex;
}

.ridesOptionCard__main {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: auto;
  cursor: pointer;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.ridesOptionCard__information {
  color: #666666;
}

.ridesOptionCard__information > img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.ridesOptionCard__information > aside {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.7rem;
  padding-bottom: 0rem;
  padding-top: 0.5rem;
}

.ridesOptionCard__bottom__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.ridesOptionCard__bottom__details > span {
  margin-bottom: 0.5rem;
  overflow-wrap: break-word;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: rgba(33, 37, 41);
}

.ridesOptionCard__description:hover {
  text-decoration: underline;
}

.ridesOptionCard__bottom__details > p {
  font-size: 0.9rem;
  color: rgba(107, 114, 128);
}

.filtered__containerData {
  display: flex;
  width: 100%;
  flex-direction: column;
  line-height: 2rem;
  gap: 2rem;
  padding: 0rem 5.5rem;
}

.filtered__containerData > h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: #212529;
  margin-bottom: 1rem;
}

.filtered__containerData > span {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.filtered__containerData > button {
  color: #fff;
  opacity: 1;
  background-color: rgba(120, 0, 110);
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 1rem 1.25rem;
  border-radius: 9999px;
  width: 14rem;
  border: none;
  cursor: pointer;
}

.ridersOptionCard__bookNow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.ridersOptionCard__bookNow > span {
  display: flex;
}

.ridersOptionCard__bookNow > button {
  border: none;
  background-color: white;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  color: rgba(120, 0, 110);
  opacity: 1;
}

/* Filter section css starts from here */

.rideView__left__main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rideView__left__main > span {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1.75rem;
  color: rgba(33, 37, 41);
  opacity: 1;
}

.rideView__left__section1 > div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}

.rideView__left__section1 > div > span {
  font-weight: 500;
  margin-bottom: 0.75rem;
  opacity: 1;
  color: rgba(33, 37, 41);
}

.rideView__left__section2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.rideView__left__section2 > div > span {
  font-weight: 500;
  margin-bottom: 0.75rem;
  opacity: 1;
  color: rgba(33, 37, 41);
}

.rideView__priceFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rideView__clearFilter {
  text-decoration: underline;
  color: rgba(120, 0, 110);
  opacity: 1;
  font-weight: 500;
  cursor: pointer;
  font-size: 1rem;
}

/* Price Filter */

.price__filter {
  width: 300px;
  margin: 20px auto;
}

.price__slider {
  width: 100%;
  position: relative;
  display: block;
}

.price__slider::-webkit-slider-thumb {
  cursor: grab;
}

.price__indicator {
  width: 100%;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  gap: 0.2rem;
  justify-content: space-between;
}

.price_hover_tag {
  background-color: gray;
  padding: 0.3rem 0.8rem;
  margin-bottom: 0.2rem;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
}

/* Pagination css starts from here */

.ridesView__card__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.pagination > span {
  background-color: indianred;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.pagination > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.pagination > div > button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border: none;
  background-color: white;
  cursor: pointer;
}

.pagination__selected {
  font-weight: bold;
  transform: scale(110%);
  color: #212529;
}

.pagination__notActive {
  color: #adb5bd;
}

.pagination__disable {
  cursor: not-allowed !important;
}

/* media query starts */

@media (min-width: 1280px) {
  .rideView__container {
    max-width: 1280px !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

@media (min-width: 1100px) and (max-width: 1280px) {
  .rideView__header {
    padding: 1rem;
  }
}

@media (max-width: 1100px) {
  .rideView__container {
    max-width: 1100px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .rideView__header {
    flex-direction: column;
  }

  .rideView__left__main {
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    width: auto;
    padding: 2rem;
  }
  .price__filter {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .rideView__right__bottomSection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 600px) {
  .rideView__right__bottomSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .ridesOptionCard__header {
    max-width: 350px;
  }

  .rideView__container {
    display: flex;
    justify-content: center;
  }
  .rideView__right__main {
    align-items: center;
  }
}
