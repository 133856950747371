.dashboard_month_calendar {
  max-width: 98% !important;
  padding: 1rem !important;
  height: 100% !important;
  width: 98% !important;
  margin: 2rem auto !important;
}

.dashboard_month_calendar.card2 {
  background: #7c5e1e87 !important;
}
.dashboard_month_calendar.card2:hover{
  box-shadow: none !important;
  transition: none !important;
}
.dashboard_month_calendar .card__header {
  margin-bottom: 1rem;
  color: #7c5e1e;
}
.dashboard_month_calendar .card__header > div button {
  margin-inline: 0.5rem;
  background-color: rgb(124, 94, 30);
  padding: 5px;
  text-align: center;
  transition: 0.5s;
  color: #fff;
  filter: drop-shadow(1px 1px 2px #e4e4e4);
  border: 1px solid rgb(124, 94, 30, 0.75);
  border-radius: 5px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: right center;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}
.dashboard_month_calendar .card__body {
  border-bottom: none;
  border-top: none;
}
.dashboard_month_calendar .card__body--dates {
  width: 100% !important;
  height: 38rem !important;
  gap: 0 !important;
}
.dashboard_month_calendar .card__body--dates>div {
  border: 1px solid #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:1rem
}
.dashboard_month_calendar .card__body--dates div ul li{
  font-size: 0.8rem !important;
  list-style: none;
  text-align: start;
  margin: 0.2rem 0rem;
  color: #7c5e1e;
  font-weight: 600;
  cursor: pointer;
}
.dashboard_month_calendar .card__body--dates>div div p{
  font-size: 0.85rem !important;
  line-height: 12px !important
} 
.dashboard_month_calendar .card__body--dates>div div p:hover + ul{
  display: block;
  transform: scale(1);
}
.dashboard_month_calendar .card__body--dates>div div ul{
  display:none;
  border: 1px solid #7c5e1e;
  border-radius: 0.2rem;
  background-color: #e4e4e4;
  padding: 0.5rem;
  z-index: 999;
  position: absolute;
  width:200px;
  transform: scale(0.2);
  transition: all 0.3s ease-in-out;
}
.dashboard_month_calendar .card__body--dates>div li {
  font-size: 1.2rem !important;
  /* width:5;
    height:50%; */
  border-radius: 50%;
}
.dashboard_month_calendar .card__body--dates>li.active {
  background: #de6e46;
  color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
/* .dashboard_month_calendar .card__body--dates div li::after{
    content: '';
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    width:50%;
    height: 50%;
    background-color:rgb(203, 98, 22);
    border-radius: 50%;
    z-index:1000;
} */
.dashboard_month_calendar .card__body--days {
  width: 100% !important;
  /* color: #fff !important; */
}
.dashboard_month_calendar .card__body--days div {
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard_month_calendar .card__body--days div > li{
    color: #fff;
}
