.tableBody {
  text-align: center;
}

.table-list td,
.table-list th {
  border: 1px solid #ddd;
  padding: 8px;
}

.date-container {
  padding: 2%;
  margin-left: 12px;
  border-left: 1px solid black;
}

.quotationregistrationh3 h3::before {
  font-family: "Poppins", sans-serif;
  /* background-color: #977e48; */
  background-color: black;
  color: #f2f2f2;
  padding: 4px 8px;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 8px;
  font-size: 15px;
}

.q-date h3::before {
  content: "1";
  padding-inline: 12px;
}

.quotation_info h3::before {
  content: "2";
  padding-inline: 12px;
}

.quotation_service h3::before {
  content: "3";
  padding-inline: 10px;

}

/* Styling for the table container */
.table-container {
  height: fit-content;
  /* Set the height as needed */
  overflow: auto;
  margin-block: 15px;
}

/* Styling for the table header */
.tableHeader {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  /* Set the background color as needed */
  z-index: 1;
  background-color: #bebb9e;
  text-align: center;
}

/* .add-remove-btns{
  position: relative;top: -23px;display: flex;left: 135px;gap: 10px;
} */

.service-addbtn {
  display: flex;
  justify-content: space-between;
}

.addRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-direction: row;
  width: 75px;
  background: #f1ebda;
  padding: 6px;
  border-radius: 10px;
  cursor: pointer;
}

.removeRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-direction: row;
  width: 35px;
  background: #f1ebda;
  padding: 6px;
  border-radius: 10px;
  cursor: pointer;
}


/* Quotation Print Preview @start */
.q-print-btn {
  position: absolute;
  top: 87px;
  right: 85px;
}

.print-preview {
  width: 100%;
  padding: 5rem;
}

.quotation-table {
  width: 100%;
  margin-block: 1rem;
}

.date-content {
  margin-top: 10px;
  margin-bottom: 10px;
}

.quotation-date {
  display: flex;
  gap: 2.5rem;
  justify-content: space-between;
}

.q-user-detaill {
  display: flex;
  justify-content: space-between;
}

.quotation-detail-table {
  width: 100%;
}

.quotation-detail-table th {
  text-align: start;
}

.q-service-body tr:nth-child(odd) {
  background-color: #f1ebda;
  height: 35px;
}

.q-service-body tr:nth-child(even) {
  background-color: #dcd4d6a6;
  height: 35px;
}



/* Quotation Print Preview @end */

/* Planner Card @start */


.planner-body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.planner-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.planner-body::-webkit-scrollbar-thumb {
  background: #7c5e1e;
}


.gross-price {
  cursor: pointer;
  position: relative;
}

.gross-price:hover .planner-container {
  display: block;
  z-index: 1000;
}

.planner-container {
  position: absolute;
  width: 250px;
  min-height: 50px;
  background: transparent;
  display: none;

}

.planner-container::before {
  position: absolute;
  top: -7px;
  left: 2px;
  content: "";
  background: transparent;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #9f8653;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.planner-content {
  position: relative;
  border: 1px solid #9f8653;
  border-radius: 5px;
}

.planner-heading {
  position: sticky;
  top: 0;
  background: #9f8653;
  font-size: 15.5px;
  font-weight: bold;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.planner-body {
  height: 200px;
  overflow: auto;
}

.planner-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-between;

}

.planner-name,
.planner-price {
  padding: 5px;
  font-size: 13.5px;
}

.planner-list:nth-child(odd) {
  background-color: #f1ebda;
}

.planner-list:nth-child(even) {
  background-color: #d4d3d5f7;
}

.planner-footer {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: #9f8653;
  font-size: 15.5px;
  font-weight: bold;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#total_amounts {
  text-align: right;
  color: black;
  -webkit-text-fill-color: rgb(4 4 4);
}

#total_discounts {
  text-align: right;
  -webkit-text-fill-color: rgb(4 4 4);
}

#total_taxable_amounts {
  text-align: right;
  -webkit-text-fill-color: rgb(4 4 4);
}

#total_taxes {
  text-align: right;
  -webkit-text-fill-color: rgb(4 4 4);
}

#net_amounts {
  text-align: right;
  -webkit-text-fill-color: rgb(4 4 4);
}

#paid_amounts {
  text-align: right;
  -webkit-text-fill-color: rgb(4 4 4);
}

#balances {
  text-align: right;
  -webkit-text-fill-color: rgb(4 4 4);

}

/* Planner Card @end */


.css-12abb9j-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 3px !important;
}

.css-etiz0k-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px 0px 0px 3px !important;
}

.css-xd03m9-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px 0px 0px 3px !important;
}

.not-allowed {
  cursor: not-allowed;
}






