/* .event-day-view {
  font-family: Arial, sans-serif;
  width: 600px;
  margin: 0 auto;
}

.date-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-header h2 {
  margin: 0;
}

.event-list {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 10px;
}

.time-column {
  font-weight: bold;
}

.event-column {
  display: flex;
  flex-direction: column;
}

.event {
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 10px;
}

.event-time {
  font-weight: bold;
}

.event-title {
  margin-top: 5px;
}

.no-events {
  color: #999999;
  font-style: italic;
} */



.event-list::-webkit-scrollbar {
  width:10px;
}

/* Track */
.event-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.event-list::-webkit-scrollbar-thumb {
  background:#7c5e1e; 
  border-radius: 10px;
}

/* Handle on hover */
.event-list::-webkit-scrollbar-thumb:hover {
  background: rgba(124, 94, 30,0.75); 
}
.event-day-view {
  width: 90%;
  margin-inline: auto;
}
.date-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #7c5e1e;
  /* background-image: linear-gradient(132deg, #5f9ea0 0%, #16A085 100%); */
  filter: drop-shadow(1px 1px 3px #7c5e1e);
  padding: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  min-width: 300px;
}
.date-header button {
  /* background-image: linear-gradient(to right, #2b5876 0%, #4e4376  51%, #2b5876  100%); */
  background-color: rgb(124, 94, 30);
  margin: 10px;
  padding: 5px;
  text-align: center;
  transition: 0.5s;
  color: #fff;
  filter: drop-shadow(1px 1px 2px #e4e4e4);
  border: 1px solid rgb(124, 94, 30 , 0.75);
  border-radius: 5px;
  width: 27px;
  display:flex;
  justify-content: center;
  align-items: center;
  background-position: right center;
  color: #fff;
  text-decoration: none;
  font-weight: bold;

}
.date-header button:hover {
  background
  : black;
  cursor: pointer;
}

.date-header h2 {
  margin: 0;
  text-transform: uppercase;
  font-size: 100%;
  text-align: center;
  color: #fff
}
.event-list {
  overflow: auto;
  height:calc(100vh - 13vw);
  position: relative;
}
.hour-events {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  /* border-bottom: 1px solid black; */
  padding-bottom: 10px;
}
.time-column {
  padding: 12px;
  background: cadetblue;
  filter: drop-shadow(2px 4px 6px black);
}
.event-column {
  width: 100%;
  background-color: #7c5e1e47;
  color: #7c5e1e;
  font-weight: bold;
  padding-left: 10px;
  cursor: pointer;
}

.no-events, .event {
  width: 100%;
  cursor: pointer;
  /* background-color: #7c5e1e47;
  color: #7c5e1e;
  font-weight: bold;
  padding-left: 10px; */
}
.time-column {
  padding: 10px;
  background: #7c5e1e;
  filter: drop-shadow(1px 1px 3px #7c5e1e);
  color: #fff;
}

