.calendar {
  padding: 1rem 2rem;
}

.calendarModal_wrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(132, 137, 146, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
}

.calendarModal_container {
  width: 40rem;
  height: auto;
  background-color: white;
  border-radius: 10px;
  position: fixed;
}

.calendarModal_heading {
  display: flex;
  margin: 1rem 2.5rem;
  align-items: center;
  justify-content: space-between;
}

.calendarModal_inputs {
  display: flex;
  height: 30rem;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  overflow-y: auto;
}

.calendarModal_inputs input {
  outline: none;
  width: 90%;
  border: 1px solid var(--primary-color);
  padding-left: 0.8rem;
  border-radius: 5px;
  height: 2.5rem;
  margin-bottom: 1rem;
}

.activeEvents_card {
  width: 20rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 1rem 1rem;
}

/* .fc .fc-button-primary {
  background-color: #0e81b9;
  text-transform: capitalize;
}

.fc .fc-button-primary:disabled {
  background-color: #0e81b9;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #0e81b9;
}
.fc .fc-toolbar.fc-header-toolbar {
  flex-wrap: wrap;
  gap: 1rem;
}
*/
/* .fc-h-event .fc-event-main-frame:hover {
  background-color: #0e81b9;
}
.fc-scrollgrid-sync-table:hover {
  background-color: transparent;
} */

.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  background-color: white;
}

.fc-theme-standard td {
  background-color: white;
}

.fc .fc-col-header-cell-cushion {
  color: white;
}

/* @start */
.calenderModal-detail {
  width: 90%;
  padding: 3px 10px 40px 10px;
  border-left: 2px solid #eaecf0;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.calendarModal_inputs>span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.meeting-attendees {
  border-radius: 0.75rem;
  background: var(--Base-White, #fff);
  width: 90%;
  display: flex;
  justify-content: flex-start;
  padding: 10px 10px;
}

.meeting-attendees-name {
  padding: 5px 20px;
  display: flex;
  width: fit-content;
  gap: 10px;
  align-items: center;
}

.user-icons {
  padding: 1px 6px;
  border-radius: 50%;
  color: white;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #eaecf0);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.contactmodal_headings {
  display: flex;
  padding: 1rem;
  width: 100%;
  justify-content: space-around;
}

/* @end */

@media only screen and (max-width: 600px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .calendarModal_container {
    width: auto;
    height: auto;
  }
}

.Pending {
  background-color: #ffec47;
  border-radius: 0.875rem;
  border: none;
  padding: 0.4rem 0.8rem;
  text-wrap: nowrap;
  filter: drop-shadow(1px 2px 0px black);
  font-size: 0.875rem;
  font-weight: bold;
  min-width: 6rem;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
}

.Approved {
  background-color: green;
  border: none;
  border-radius: 0.875rem;
  padding: 0.4rem 0.8rem;
  text-wrap: nowrap;
  filter: drop-shadow(1px 2px 0px black);
  font-size: 0.875rem;
  font-weight: bold;
  min-width: 6rem;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
}

.student__calendar__details {}

.student__calendar__wrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(132, 137, 146, 0.7);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  /* top: 0; */
  top: -115px;
  z-index: 99999;
}

.calendar-top-navigation {
  display: flex;
  justify-content: flex-start;
}
.student__calendar__details{
  padding-inline: 1rem;
}
.student__calendar {

  width: 25rem;
  height: 26rem;
  /* background-color: #9f8653; */
  background-color: white;
  position: relative;
  color: black;
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 4px;
  /* padding-inline: 1.1rem; */
}

.close__modal_icons {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 20px;
  color: red;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

element.style {
  background-color: red;
  font-size: 1.8rem;
  color: white;
  border-radius: 50%;
}