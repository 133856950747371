.feedback__container > h1 {
  text-align: center;
  margin: 5rem 0rem;
}

.feedback-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feedback-form label {
  display: block;
  margin-bottom: 10px;
}

.feedback-form input[type="text"],
.feedback-form input[type="email"],
.feedback-form textarea {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  outline: none;
}

.feedback-form .satisfaction-options {
  margin-bottom: 15px;
}

.feedback-form .satisfaction-options label {
  display: block;
  margin-bottom: 10px;
}

.feedback-form .satisfaction-options input[type="radio"] {
  margin-right: 10px;
}

.feedback-form .submit-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.feedback-form .submit-btn:hover {
  background-color: #0056b3;
}

.feedback__btn {
  background-color: black;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.feedback__bottom {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.feedback__bottom > h1 {
  margin-top: 1rem;
  animation: rotateEmoji 2s linear infinite;
}

@keyframes rotateEmoji {
  from {
    transform: rotate(-20deg); /* Starting rotation angle */
  }
  to {
    transform: rotate(20deg); /* Ending rotation angle (360 degrees) */
  }
}
