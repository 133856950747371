.cart {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}
.cartWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.cartMainContainer {
  margin: 3rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
  color: #302924;
  max-width: 60rem;
}
.cartContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 50rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
  gap: 1rem;
}
.cartContent {
  display: flex;
  gap: 1rem;

  justify-content: space-between;
  padding-right: 1rem;
}
.cartImage {
}
.cartContent img {
  width: 20rem;
  height: 11rem;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cartData {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.cartCompany,
.cartLesson {
  color: #666666;
  font-weight: 500;
}

.cartCourse {
  font-size: 1.5rem;
  font-weight: 500;
  color: #302924;
}
.cartPrice {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-inline: 1rem;
  min-width: 15rem;
}
.total {
  width: 100%;
  border-top: 3px solid #00000094;
  display: flex;
  justify-content: space-between;
}
.total > p {
  font-size: 1.125rem;
  font-weight: 500;
}
.deleteBtn {
  color: #e74c3c;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0px 2px 5px #00000045;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .cartMainContainer {
    max-width: 35rem;
  }
  .cartContent > img {
    width: 100%;
    height: 15rem;
  }
  .cartContent {
    flex-direction: column;
    padding-right: 0rem;
  }
  .cartData {
    align-items: center;
    gap: 0.5rem;
  }
  .cartPrice,
  .cartContent {
    width: 100%;
    padding-bottom: 1rem;
  }
  .deleteBtn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
  }
  .deleteBtn:hover {
    background-color: #fff;
  }
}

/* checkout__booking__details
checkout__booking__cart1
checkout__booking__cart2
checkout__inActive
checkout__booking__details
checkout__booking__cart1
checkout__booking__cart2
checkout__inActive */
