@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Poppins:wght@300&display=swap");

.horseAgreeSection {
  padding-block: 4%;
  width: 90%;
  margin: auto;
}


.hAgreeUl {
  text-align: justify;
  font-size: 15px;
  margin-left: 40px;
}

.hAgreeUl ul li {
  text-align: justify;
  line-height: 30px;
}


.hAgreeFoot {
  margin-block: 2rem;
}

.hAgreeHead{
  margin-block: 40px;
}
.hAgreeHead h4 {
  letter-spacing: 1.2px;
  font-family: "Open Sans", sans-serif;
  color: "#333333";
  font-size: 15px;
}

.hAgreeHead p {
  font-size: 13.5px;
}

.bg-color-active{
  background-color: #f1ebda;
}