.ownerAgreeSection{
    padding-block: 4%;
    
    width: 90%;
    margin: auto;
}

.oAgreeHead{
  margin: 5% 2% 2% 0%;
}
.oAgreeUl {
padding: 5px 50px;
text-align: justify;
}
.oAgreeUl ol li {
padding: 6px 5px ;
text-align: justify;
}
.oAgreeUl ul  {
padding: 6px 30px ;
text-align: justify;
list-style-type:disc;
}


.ownerAgreement p {
   font-size: 12.5px;
   display: flex;
   align-items: center;
   font-weight: bold;
   line-height: none !important;
   
 }

 .oAgreeFoot{
  margin-block: 2rem;
 }