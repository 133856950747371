.card {
  max-width: 20rem;

  margin: 5rem auto;
  /* display: grid; */
  grid-template-rows: 8rem auto 10rem;

  box-sizing: border-box;
  border: 1px solid #bebc756e;
  border-radius: 5px;
}
.card:hover {
  box-shadow: 0 0.5rem 1.5rem #1b405e;
  transition: 300ms ease-in-out;
}
.card__header,
.card__footer {
  padding: 0 1.25rem;
  color: #7d8994;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* padding: 10px; */ /*p*/
}
.card__body {
  /* padding: 1.5rem 2rem; */
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.card__body--days {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  list-style: none;
  width: 20rem;
  padding: 0px !important;
}
.card__body--days li {
  flex: 1;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: #7d8994;
  margin: 0 1px;
  /* padding-inline: 10px !important; */ /*P*/
}
.card__body--dates {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  text-align: center;
  list-style: none;
  width: 20rem;
  padding: 0px; /*R*/
  /* padding-block: 20px !important; */ /*P*/
}
.card__body--dates>li {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  font-weight: bold;
  position: relative;
}
/* 
.card__body--dates li.prev,
.card__body--dates li.next {
  color: #ccc;
}
.card__body--dates li.active {
  background: #de6e46;
  color: #ffffff;
  border-radius: 50%;
}
.card__body--dates li.circle {
  border: 2px solid;
  border-radius: 50%;
} */
.card__body--dates li.circle span {
  position: absolute;
  top: -0.75rem;
  right: -0.5rem;
  width: 0.85rem;
  height: 0.95rem;
  font-size: 0.8rem;
  color: #ffffff;
  border-radius: 50%;
  display: none;
}
.card__body--dates .circle.orange {
  border-color: #de6e46;
}
.card__body--dates .circle.orange span {
  background: #de6e46;
}
.card__body--dates .circle.purple {
  border-color: #ae5989;
}
.card__body--dates .circle.purple span {
  background: #ae5989;
}
.card__footer {
  color: #333333;
  font-weight: bold;
}
.card__footer .add {
  text-decoration: none;
  color: inherit;
  /* border: 2px solid #de6e46; */
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  /* padding-top: 0.25rem; */

  display: grid;
  place-items: center;
}
