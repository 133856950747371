.login-section {
  background-color: #efe8d8;
  background-image: url("../../Assets/Images/loginInBG.png");
  background-size: 100%;
  background-attachment: fixed;
}

.login-page {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.login-left-container {
  width: 40vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.top-left-container {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mid-left-container {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  width: 36rem;
}

.left-mid-left-container {
  padding: 20px;
}

.right-mid-left-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.bottom-left-media-container ul {
  display: flex;
  gap: 8px;
  padding-block: 10px;
}

.bottom-right-mid-left-container {
  padding-block: 2rem;
}

.app-installer-container {
  padding-block: 1rem;
  width: 90%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.left-app-intaller {
  width: 10rem;
}

.left-app-intaller img {
  width: 10rem;
}

.right-app-intaller {
  width: 10rem;
}

.right-app-intaller img {
  width: 10rem;
}

.media-container {
  width: 36px;
  height: 36px;
  background-color: #877749;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-right-container {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.login-module-container {
  display: flex;
  gap: 5rem;
  flex-direction: column;
  padding-top: 10rem;
}

.container {
  background-color: #fefdfc;
  padding: 40px;
  width: 35rem;
  margin: auto;
}

.login-header {
  text-align: center;
  color: #826426;
  text-transform: uppercase;
}

.login-form {
  margin-top: 30px;
}

.login-form .form-label {
  margin-bottom: 5px;
  color: black;
  font-size: 13px;
}

.login-section .input-field {
  width: 100%;
  padding: 13.5px;
  border-radius: 10px;
  color: black;
  border: 1px solid black;
}

.login-input-field {
  margin: 0.3rem 0;
}

.login-section .form-group {
  margin: 0.7rem 0;
}

.login-section .form-group:nth-child(3) {
  margin-bottom: 1rem;
}

.password-field {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.password-icon:hover {
  color: black;
  background-color: #efe8d8;
}

.login_as_student {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: underline;
  color: #836628;
}

.login-button {
  background-color: #836628;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-weight: bolder;
  font-size: 15px;
  text-transform: uppercase;
}

.login-button[type="submit"]:hover {
  background-color: #f4f0e5;
  color: black;
}

#OTPname::-webkit-outer-spin-button,
#OTPname::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-right-container-mobile {
  display: none;
}

/* Styles for screens with a maximum width of 600 pixels */
@media screen and (max-width: 1200px) {
  .login-left-container {
    display: none;
  }

  /* .login-right-container {
    display: none;
  }

  .login-section {
    display: none;
  }

  .login-right-container-mobile {
    display: block;
    padding: 70px 20px;
  }

  .login-form {
    margin-top: 300px;
  }

  .forget-password-container {
    background-color: #fefdfc;
    margin-block: 70px;
    padding: 20px;
  } */
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
  .login-right-container {
    width: 100%;
    align-items: center;
    height: auto;
  }
  .login-module-container {
    padding: 5rem 3rem;
    width: 100%;
  }
  .login__footer > a {
    font-size: 0.7rem !important;
  }
  .login-button {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 1020px) {
  .container {
    width: 100%;
  }
  .login-right-container {
    width: 100%;
    align-items: center;
    height: 100%;
  }
  .login-module-container {
    padding: 5rem 3rem;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  /* .mid-left-container {
    flex-direction: column;
  }
  .left-mid-left-container {
    padding: 0px;
  }
  .right-mid-left-container {
    padding: 0px;
  } */
  .app-installer-container {
    flex-direction: column;
  }
  .login-module-container {
    padding-top: 5rem;
  }
}
