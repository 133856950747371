.need-container {
  background: url("../../../Assets//Images//need.png") no-repeat 100%;
  background-size: 65%;
  padding: 2% 3%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px #ccc;
  margin: 1rem 0;
}
.need-container h2 {
  color: #707070;
  font-size: 1.4rem;
}
.need-container ul {
  margin-top: 0.5rem;
  width: 11rem;
}
.need-container ul li {
  list-style: none;
  margin: 0.2rem 0;
}
.need-container ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
}
.need-container ul li a svg {
  margin-right: 0.2rem;
  color: #7c5e1e;
  font-size: 1.3rem;
}

/* Feedback CSS */
.feed-value-container {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-block: 10px;
}

.feed-value-rate {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.feed-value-comment {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  padding-block: 10px;
  /* background-color: yellow; */
}
