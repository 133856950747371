.settings-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}


.settings-card-container {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    border-radius: 10px;
    border-bottom: 1px solid #edebe5;
    background: #fff;
    padding: 10px 30px;
    justify-content: space-between;
    box-shadow: 0px 0px 2px 0px #aaa;
    height: 120px;
    width: 340px;
    cursor: pointer;
}
  
.setting-card-top-container{
    display: flex;
    gap: 30px;
    align-items: center;
 
}
.settings-card-container:hover {
    background-color: #f1f0e8;

    .settings-text {
        color: #7c5e1e;
    }

    .settings-images {
        color: #7c5e1e;
    }
}



.settings-text {
    text-transform: capitalize;
    /* font-size: 2px; */
    font-size: 1.1rem;
    font-weight: 600;
    transition: all 0.2s linear;
    margin: 0px;
    padding: 0px;
    color: #333333;

}

@media screen and (max-width: 1200px) {
    .settings-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

}