.artical-section {
  background-color: #000000;
  display: flex;
  color: #bebb9e;
  padding: 2rem 2rem;
  gap: 100px;
  flex-wrap: wrap;
  justify-content: center;
 
}
.aboutus-articalsection-edit-head-btn {
  position: absolute !important;
  top:2% !important;
  right: 2% !important;
}
.aboutus-articalsection-edit-para-btn {
  position: absolute !important;
  top: 100% !important;
  right: 2% !important;
}
.header-white {
  background: linear-gradient(to right, grey, white) !important;
  color: #000000 !important;
}
.header-voilet {
  background-color: #1a0061 !important;
  color: #000000 !important;
}
.header-skyBlue {
  /* background: linear-gradient(to right, #3fb896, white) !important; */
  background-image: linear-gradient(
    to top,
    #fff1eb 0%,
    #ace0f9 100%
  ) !important;
  color: black !important;
}
.header-grassy {
  /* background-color: #d79922 !important; */
  background-image: linear-gradient(
    to top,
    #c1dfc4 0%,
    #deecdd 100%
  ) !important;
  color: #000000 !important;
}
.header-roseate {
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%) !important;
  color: #000000 !important;
}
.header-pink {
  /* background: linear-gradient(to right, #f8af91, #dd6574) !important; */
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%) !important;
  color: #000000 !important;
}
.header-gold {
  /* background-color: #106466 !important; */
  background-image: linear-gradient(
    135deg,
    #ecec95 0%,
    #e2d1c3 100%
  ) !important;
  color: black !important;
}

.header-orange {
  /* background-color: #106466 !important; */
  background-image: linear-gradient(
    to top,
    #e6b980 0%,
    #eacda3 100%
  ) !important;
  color: #000000 !important;
}
.Left-content-maindiv {
  width: 90%;
}
.Left-content {
  border: 1px solid #605b5b;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
  /* padding: 10px; */
  box-shadow: 2px 2px 6px black;
}
.Left-content:hover {
  box-shadow: 2px 2px 10px black;

  /* transition: 100ms ease-in; */
}
.main-heading {
  /* width: 95%; */
  background-color: #222222;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: azure;
  gap: 0.6rem;
  border-radius: 5px;
  /* box-sizing: border-box; */
}
.main-heading p {
  margin: 0px;
}
.sub-heading {
  width: 100%;
  font-family: var(--main-font);
  font-size: 2.5rem;

}
.sub-heading p {
  margin: 0px;
  padding: 5px;
  /* border-bottom: 1px solid; */
}
.body {
  width: 95%;
  text-align: left;

  padding: 1rem;
}
.body h4 {
  margin: 1px;
  margin-top: 10px;
}
.photogallary {
  margin-top: 3rem;
}
.Rigth-content {
  width: 30%;
  border: 2px solid rgb(81, 132, 4);
}
.link {
  color: inherit;
  text-decoration: none;
}
@media screen and (width<=700px) {
  .artical-section {
    background-color: #000000;
    display: flex;
    color: #bebb9e;
    padding: 2rem 2rem;
    gap: 100px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Left-content-maindiv {
    width: 100%;
  }
  .Rigth-content {
    width: 80%;
  }
}
