.footer {
  background-color: black;
  width: 100%;
  min-height: 200px;
  /* border-top: 54px solid #000; */
  padding-top: 50px;
}
.header-white {
  background: linear-gradient(to right, grey, white) !important;
  color: #000000 !important;
}
.header-pink {
  background-color: #faaca8;
  background-image: linear-gradient(
    to right,
    #faaca8 0%,
    #ddd6f3 100%
  ) !important;
}
.header-skyBlue {
  /* background: linear-gradient(to right, #3fb896, white) !important; */
  background-image: linear-gradient(
    to right,
    #fff1eb 0%,
    #ace0f9 100%
  ) !important;
}
.header-grassy {
  /* background-color: #d79922 !important; */
  background-image: linear-gradient(
    to right,
    #c1dfc4 0%,
    #deecdd 100%
  ) !important;
  color: #000000 !important;
}
.header-roseate {
  background-color: #d9afd9;
  background-image: linear-gradient(
    to right,
    #d9afd9 0%,
    #97d9e1 100%
  ) !important;
  color: #000000 !important;
}
.header-gold {
  /* background-color: #106466 !important; */
  background-image: linear-gradient(
    to right,
    #fdfcfb 0%,
    #e2d1c3 100%
  ) !important;
  color: black !important;
}
.header-orange {
  /* background-color: #106466 !important; */
  background-image: linear-gradient(
    to right,
    #e6b980 0%,
    #eacda3 100%
  ) !important;
  color: #000000 !important;
}

.footer .footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
  padding: 25px;
  border-top: 5px solid #bfba9d;
}
.footer .footer-container .footer-contents {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: white;
}

.footer .footer-container .footer-contents h2 {
  font-size: 1.7em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: black;
}

.footer .footer-container .footer-contents p {
  font-family: "HelveticaRegular";
}
.footer .footer-container .footer-contents p a {
  text-decoration: none;
  color: black;
}
.footer .footer-container .footer-contents ul {
  margin: 0;
  padding-inline-start: 0px;
}
.footer .footer-container .footer-contents .social-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer .footer-container .footer-contents ul.social-icon li {
  list-style: none;
  margin-right: 19px;
}

.footer .footer-container input {
  background-color: transparent;
  color: #fff;
  border: none;
}
