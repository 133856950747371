.modal.form-container {
    width: 95%;
    margin-inline: auto;
    background-color:transparent !important ;
    margin-top: 1.5rem;
    border-radius: 10px;
    padding: 1rem;
}

.ev-bg-color{
    background-color: #e0e0e0;
}