.registration-card-container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1.2rem;
  margin: 2rem 0;
}

.dashboard-header {
  text-transform: capitalize;
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
  color: #333333;
  display: flex;
  align-items: center;
}

.quicklinks .quickhead {
  padding: 0px 5px;
  color: #9f8754;
  font-size: 26px;
  font-weight: bold;
  border-bottom: 2px solid #eee;
  display: block;
}

.registration-card-container .registrationCard a {
  border: 1px solid #7c5e1e;
  position: relative;
  padding: 0.4rem;
  border-radius: 8px;
  /* width: 140px; */
  /* height:100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s all;
  /* -webkit-box-shadow: 1px 1px 10px 1px rgba(124, 94, 30, 0.75);
  -moz-box-shadow: 1px 1px 10px 1px rgba(124, 94, 30, 0.75); */
}

.registration-card-container .registrationCard:hover {
  /* box-shadow: 0px 0px 5px 0px rgba(124, 94, 30, 0.65); */
  background-color: #f1f0e8;
}

.registration-card-container .registrationCard:hover a>div:nth-child(2) {
  background-color: #7c5e1e;
  color: #fff;
}

.registration-card-container .registrationCard:hover a>div:nth-child(1) span {
  color: #7c5e1e;
}

.registration-card-container .registrationCard a img {
  width: 55px;
  height: 55px;
  object-fit: fill;
}

.registration-card-container .registrationCard a>div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.registration-card-container .registrationCard a>div:nth-child(1) span {
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 0.2s linear;
}

.registration-card-container .registrationCard a>div:nth-child(2) {
  position: absolute;
  right: 1%;
  top: 1%;
  background-color: #e7e2d5;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  transition: all 0.3s linear;
}

.registration-card-container .registrationCard a span {
  color: var(--primary_color);
}

.status-container {
  margin: 1rem 0;
}

.status-container .status-top {
  display: flex;
}

.status-container .status-top button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  width: 130px;
  background: linear-gradient(to bottom, #9f8754 0, #72520e 100%);
  margin-left: 0.5rem;
  border-radius: 8px;
  padding: 0 0.3rem;
  color: #fff;
  border: none;
  text-transform: capitalize;
  cursor: pointer;
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 1rem;
}

.chart-container>div {
  flex-basis: 49%;
  height: 250px;
}

.chart-container>div canvas {
  height: 185px;
  width: 100% !important;
}


/* Todays pending event container */
.events-container {
  border-radius: 8px;
  border: 1px solid #7c5e1e;
}

.events-container>h4 {
  background-color: #e7e2d5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0.6rem 1rem;
  display: flex;
}

.events-notification {
  padding: 0.6rem;
  position: relative;
  overflow: hidden;
  height: 200px
}

.events-notification > ul li{
  padding-block: 0.5rem;
}

/* Todays pending planner container */
.planners-container {
  border-radius: 8px;
  border: 1px solid #7c5e1e;
}

.planners-container>h4 {
  background-color: #e7e2d5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0.6rem 1rem;
  display: flex;
}

.planners-notification {
  padding: 0.6rem;
  position: relative;
  overflow: auto;
  height: 200px

}

.planners-notification > ul li{
  padding-block: 0.5rem;
}

/* .events-notification .scrolling-text {
  bottom: 0; 
  animation: scroll-up 10s linear infinite;
}

.planners-notification .scrolling-text {
  bottom: 0; 
  animation: scroll-up 10s linear infinite;
} */
/* 
@keyframes scroll-up {
  0% {
    transform: translateY(100)
  }
  100% {
    transform: translateY(-100%);
  }
} */

@media screen and (max-width :1000px) {
  .chart-container>div {
    flex-basis: 90%;
    height: 300px;
  }

  .chart-container>div canvas {
    height: auto;
    width: 100% !important;
  }
}

.service-header {
  font-family: sans-serif;
  margin: 2rem auto;
  color: var(--primary_color);
  text-align: center;
  font-size: 30px;
  /* max-width: 600px; */
  position: relative;
}

.service-header:before {
  content: "";
  display: block;
  width: 38%;
  height: 2px;
  background: #e2cc9d;
  left: 0;
  top: 50%;
  position: absolute;
}

.service-header:after {
  content: "";
  display: block;
  width: 38%;
  height: 2px;
  background: #e2cc9d;
  right: 0;
  top: 50%;
  position: absolute;
}

.services-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f8e9cd;
  padding: 1rem;
  gap: 1.2rem;
}

.services-container .service button {
  background-color: #fff;
  border: 1px solid #7c5e1e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  width: 250px;
  box-shadow: inset 0px 0px 3px 0px rgba(163, 148, 100, 1);
}

.services-container .service button img {
  margin-right: 0.2rem;
}

.event-calendar-container {
  padding: 0.5rem 1rem;
  border: 0.5px solid rgba(124, 94, 30, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
}

.event-calendar-container h3 {
  font-size: 1.1rem;
}

.event-calendar-container>div span {
  color: #7c5e1e;
  font-weight: 600;
  font-size: 0.9rem;
}

.event-calendar-container img {
  filter: invert(50%);
}

.user-dp-container {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
}

.user-dp-container .imgBx {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px dashed #ccc;
  padding: 2px;
}

.user-dp-container .imgBx img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px dashed #ccc;
  /* padding: 2px; */
}

.user-dp-container h4 {
  font-weight: 650;
  line-height: 1.2;
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
}

.user-dp-container h4 span {
  color: #9f8754
}

.user-dp-container p {
  font-size: 0.8rem;
  font-weight: 600;
  color: #000;
  line-height: 1.1rem;
  text-transform: capitalize;
  margin-bottom: 0.4rem;
}

.user-detail-container {
  padding: 0.5rem;
}

.user-detail-container a div {
  border: 1px solid #333;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.4rem 0;
  padding: 0 0.2rem;
  width: max-content;
}

.user-detail-container a div span {
  font-size: 0.7rem;

}

.user-detail-container a div svg {
  font-size: 0.8rem;
  margin-right: 0.3rem;
}

.user-detail-container p {
  margin: 0.4rem 0;
  font-size: 0.8rem;
  color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.user-detail-container p svg.active {
  color: #3c763d;
}

.user-detail-container p span.active {
  color: #3c763d;
}

.user-detail-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  margin: 0.4rem 0;
  padding: 0.2rem 1rem;
  border: none;
  cursor: pointer;
}

.user-detail-container button svg {
  margin-right: 0.2rem;
  font-weight: 600;
}