
.form-container{
    width:90%;
    margin-inline:auto;
    /* background-color: #f1ebda; */
    background-color:transparent;
    margin-top:1.5rem;
    border-radius: 10px;
    padding:3%;
}
.form-container form{
   margin-top:1.3rem;
}
.form-container form label{
    font-size:0.95rem;
 }
.form-container form .css-wb57ya-MuiFormControl-root-MuiTextField-root{
    margin-top:0.5rem  !important;
}
.form-container form textarea{
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 12px;
    box-sizing: border-box;
    background: none;
    height: 18px;
    margin-top: 0.5rem;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 16.5px 14px;
    overflow: hidden;
}