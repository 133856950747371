.card-container{
    height: 21rem;
    width: 15rem;
    /* border: 1px solid black; */
    /* padding: 1px; */
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 4px 0px black;
}
.p-text-gray{
    color: rgb(142, 141, 141);

}
/* .p-text-black{ */
    /* font-size: 1.2rem; */
/* } */
/* .p-text-black1{ */
    /* font-size: 1.1rem; */
/* } */

.info_coursed{
    padding: 10px;
}
.p-purshed{
    margin-left: 8.3rem;
    font-size: 0.85rem;
    border-radius: 5px;
    padding: 0.07rem;
    text-align: center;
    width: 5rem;
    color:white;
    background-color:rgb(81, 167, 81);
}
.p-pending{
    margin-left: 8.3rem;
    font-size: 0.85rem;
    border-radius: 5px;
    padding: 0.07rem;
    padding-bottom:0.2rem ;
    text-align: center;
    width: 5rem;
    color:rgb(54, 52, 52);
    background-color:rgb(221, 203, 68);
}