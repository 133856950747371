@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Poppins&family=Ubuntu:wght@300;400;500&display=swap');

:root {
  --primary-color: linear-gradient(to bottom, #9f8754 0, #72520e 100%);
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  /* font-family: "Ubuntu", sans-serif; */
  font-family: Gilroy, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7c5e1e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(124, 94, 30, 0.75);
}

html {
  scroll-behavior: smooth;
}

/* -----------------------------Article section Customizable ----------------------------- */
.Article-Editor {
  width: 100%;
  background-color: black;
}
.Article-Editor-show {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Article-Editor-hide {
  display: none;
}
.Article-Editor-maindiv {
  width: 70%;
  border: 5px solid #bebb9e;
  margin: 10px;
  border-radius: 10px;
  background-color: #e0e0e0;
  color: black;
  height: 25rem;
  overflow: scroll;
  /* font-family: monospace; */
}

.all_table_list {
  width: 95%;
  margin: 2rem auto;
}

.table_header {
  margin-block: 2rem;
}

.table_header span {
  /* color: "#544530"; */
  font-weight: 500;
  font-size: 2rem;
}

.table_header button {
  background: linear-gradient(to bottom, #9f8754 0, #72520e 100%);
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  float: right;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.table_header button:hover {
  background-color: #96947c;
}
.css-xg016x {
  height: fit-content !important;
}
/* test */
/* =======================Registrations Form Start=================== */
.registration-card {
  /* background-color: #e3d9c1; */
  /* background-color: #f1ebda; */
  width: 95% !important;
  margin-inline: auto;
  /* margin-block: 1rem; */
  padding: 3%;
  border-radius: 10px;
}
.registration-card > h2 {
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
.registration-card > p {
  margin: 0px;
  padding: 0px;
  font-size: 15px;
}
.registration_top_header {
  margin-block-end: 5%;
}
.registration_top_header p {
  font-weight: bolder;
  text-transform: capitalize;
  font-size: 28px;
  margin: 0px;
  padding: 0px;
  color: #333333;
  line-height: 58px;
}
.registration_top_header span {
  font-size: 15px;
  margin: 0px;
  padding: 0px;
  color: #333333;
}
.bg-color {
  background-color: white;
  font-size: 5px;
  padding: 5px;
  margin-top: 0.5rem !important;
  color: black !important;
  border-radius: 12px !important;
}

.autocomplete-mt {
  margin-top: 0.5rem !important;
}

.inputparts {
  /* margin-block: 15px; */
  /* background-color: #f9f7f3; */
  padding: 2%;
  margin-left: 12px;
  /* border-radius: 10px; */
  border-left: 1px solid;
}

.upload_document {
  padding: 2%;
  border-radius: 3px;
  /* margin-left: 18px; */
  background-color: #f9f7f3;
}

.custom-select.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #977e48 !important;
}

.selectInput {
  margin-top: 0.5rem;
}

.MuiOutlinedInput-root .Mui-focused fieldset {
  border-color: '#977e48' !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  height: 45px !important;
  border-radius: 12px !important;
}

.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3px 4px 7.5px 5px !important;
}
.MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

.MuiInputBase-input-MuiOutlinedInput-input:invalid {
  padding: 10.5px !important;
}
.eq-file-input {
  cursor: pointer;
  padding-block: 11px;
  padding-inline: 14px;
  font-size: 12px;
  border-radius: 12px !important;
  border: 1px solid #0003;
  background-color: #fff;
}

.form-wrapper-container {
  background-color: #fff;
  background-image: url('./Assets/Images/bgtriangle.png');
  background-size: 100%;
  background-attachment: fixed;
  min-height: 100vh;
}

.form-button {
  margin-top: 2rem !important;
  font-size: 12px !important;
  margin-inline: 0.5rem;
  padding: 0.3rem 0.5rem !important;
  border-radius: 0.2rem !important;
  background: var(--primary-color) !important;
  color: #fff !important;
  border-color: var(--primary-color) !important;
  font-size: 0.9rem !important;
  cursor: pointer !important;
}

/* Notification center MUI */

.MuiStack-root > :not(style) ~ :not(style) {
  margin-top: 0px !important;
}

:root {
  --fc-daygrid-event-dot-width: 5px;
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #9f8653;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: red;
}

.fc-theme-standard th {
  border: 1px solid var(--fc-border-color);
  background-color: var(--fc-border-color);
  color: var(--fc-page-bg-color);
}
a.fc-col-header-cell-cushion {
  color: #fff;
}
.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  padding: 1rem;
}

@media screen and (max-width: 678px) {
  .fc .fc-toolbar {
    flex-direction: column;
    gap: 1rem;
  }
}




/* PhoneInput */
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 12px !important;
  line-height: 25px;
  height: 43px !important;
  width: 100% !important;
  outline: none;
}