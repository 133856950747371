/* styles.css */

.support-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 3rem;
  padding: 2rem;
  height: 93%;
}
.mapContainer {
  width: 70%;
  height: 100%;
  overflow: hidden;
}
  
.support-heading {
  font-size: clamp(1rem, 2vw,1.6rem);
  color: #877749;
  padding-bottom: 1px;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  line-height: 50px;
}
  
  .headingSpan {
    border-bottom: 1px solid #877749;
    padding-bottom: 11px;
  }
  
  .mapDiv {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    height: 100%;
  }
  
  .addressContainer {
    width: 30%;
    height: 100%;
  }
  
  .para {
    color: #777;
  }
  
  .txtColor {
    color: #877749;
  }
  


  @media screen and (max-width:992px) {
    .support-container {
      flex-wrap: wrap;
    }
    .mapContainer {
      width: 100%;
      height: 68%;
      overflow: hidden;
  }  
  .addressContainer {
    width: 100%;
    height: 100%;
  }  
  .headingSpan {
    border-bottom: 1px solid #877749;
    /* padding-bottom: calc(7px + 1.19vh); */
    padding-bottom:16px;
}
  }