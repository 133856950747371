.sponser-title {
  text-align: left;
  font-size: 2em;
  text-transform: uppercase;
  font-family: "HelveticaRegular";
  padding: 8px 0 20px 33px;
  background: rgba(0, 0, 0, 0.793);
  /* border-radius: 5px; */
  color: #bebb9e;
  margin: 0;
}

.slider-div {
  width: 100%;
  padding: 10px 0 10px 0;
  background: #262626;
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
  background: #262626;
  display: none !important;
}

.slick-slide img {
  display: block;
  border-radius: 10px;
}

.partners-logo {
  padding: 0;
}

.slide-child {
  padding-inline: 5px;
}

.img-list-div {
  padding: 5px;
}

.img-list {
  display: flex;
  width: 60.5rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  border: 2px dashed burlywood;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  transition: 80ms ease-in-out;
}

.img-list:hover {
  transform: scale(1.03);
  background-color: #eadcc965;
}

.img-list-with-remove {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.img-list-left img {
  width: 100px;
  height: 100px;
  border-radius: 10%;
}

.img-list-right {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  /* width: 200px; */
}

.img-list-right i:hover {
  /* font-size: 15px; */
  cursor: pointer;
  color: red;
}

.sponsors-main-div {
  background-color: black;
}