.s-main-content {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 2rem;
  background-color: #dce1e8;
}
.student-sidebar {
  padding-inline: 1.5rem;
  margin-top: 40px;
  height: 100vh;
  display: flex;
  align-items: center;
}
.student-sidebar nav {
  display: flex;
  flex-direction: column;
}
.student-sidebar nav ul li,
.student-sidebar nav button {
  list-style: none;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9f8653;
  border-radius: 50%;
  box-shadow: 1px 2px 5px 0px #000;
  margin-block: 1rem;
  cursor: pointer;
}
.student-sidebar nav ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: capitalize;
}
.student-menu-icon {
  font-size: 1.7rem;
  color: #fff;
}

.st-menu-active {
  /* display: block;
  transition: display 2s linear 1s; */
  opacity: 1; /* Make the element visible */
  visibility: visible; /* Show the element */
  transition: opacity 0.5s ease-in-out 0s, visibility 0s 0s;
}
.st-menu-hide {
  /* display: none;
  transition: display 2s linear 1s; */

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out 0s, visibility 0s 2s;
}

@media screen and (max-width: 992px) {
  .s-main-content {
    flex-direction: column-reverse;
  }
  .student-sidebar {
    /* padding-inline: 1.5rem; */
    margin-top: 40px;
    height: 100%;
    position: sticky;
    display: flex;
    bottom: 0;
    z-index: 1;
    align-items: center;
    z-index: 2;
  }
  .student-sidebar nav {
    flex-direction: column-reverse;
  }
  .input-wrapper {
    flex-wrap: wrap;
  }
}
