@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.table table {
  font-family: 'Poppins', sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.table-list {
  overflow: auto;
}

.table-list td,
.table-list th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-list tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-list tr:hover {
  background-color: #f1ebda;
  transition: 200ms ease-in;
}

.table-list th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  /* text-align: center; */
  text-align: left;
  /* background-color: #bebb9e; */
  background: #a69061;
  /* color: white; */
  color: black;
}

.p-fixed {
  /* background-color: #72520e; */
}
.p-fixed,
td.hoverEffect.p-fixed {
  position: sticky;
  right: 0;

  z-index: 1;
}
.table-list tbody tr:nth-child(even) td.hoverEffect.p-fixed {
  background-color: #f2f2f2;
}
.table-list tbody tr:nth-child(odd) td.hoverEffect.p-fixed {
  background-color: #fff;
}

.Main-div {
  position: relative;
  width: 100%;
  margin: auto;
  font-family: 'Poppins', sans-serif;
}
.Header-column {
  position: absolute;
  top: '10px';
  left: '20px';
  /* background: linear-gradient(to bottom, #9f8754 0, #72520e 100%); */
  background-color: #f2f2f2;
  border-radius: 5px;
  width: 180px;
  padding: 13px;
}
.Header-column > Checkbox {
  padding: 20px;
}
.density1 {
  padding-block: 15px;
  text-align: center; /* Adjust the value as per your desired padding */
}
.density2 {
  padding-block: 20px;
  text-align: center; /* Adjust the value as per your desired padding */
}
.density3 {
  padding-block: 25px;
  text-align: center; /* Adjust the value as per your desired padding */
}
/* styles.css */

#density {
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f2f2f2;
  appearance: none;
  outline: none;
  cursor: pointer;
  height: 39px;
}

#density option {
  padding: 5px;
}
#density option:hover {
  background-color: #bebb9e !important;
}

#density option:first-child {
  color: #aaa;
}
.Features-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #f2f2f2; */
}
.Toggle-list label input {
  height: 20px;
  /* width: ; */
}
.Navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.Navigation button {
  background: linear-gradient(to bottom, #9f8754 0, #72520e 100%);
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Navigation button:hover {
  background-color: #96947c;
}
.NavigationButton {
  display: flex;
  gap: 10px;
  margin: auto;
}
.NavigationButton button {
  padding: 4px 4px 2px 4px;
}
.Csv > a {
  color: #fff !important;
  text-decoration: none;
  font-size: 14px;
}
.EditDiv {
  display: flex;
  width: 70px;
  justify-content: space-around;
  margin: auto;
  align-items: center;
}
.table-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.table-btn-left {
  display: flex;
  gap: 10px;
}
/* .table ::-webkit-scrollbar {
    width: 5px;
    color: #333333;
  } */
#btn_action {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.registration_top_header_table {
  /* margin-block-end: 5%; */
}
.registration_top_header_table p {
  font-weight: bolder;
  text-transform: capitalize;
  font-size: 28px;
  margin: 0px;
  padding: 0px;
  color: #333333;
  line-height: 58px;
}
.registration_top_header_table span {
  font-size: 15px;
  margin: 0px;
  padding: 0px;
  color: #333333;
}
