.content {
  max-width: 100%;
  background: #dde1e7;
  border-radius: 10px;
  padding: 40px 30px;
  box-shadow: -3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288);
  flex-grow: 1;
}

.content img {
  max-width: 10rem;
  max-height: 10rem;
  margin-top: -100px;
  border-radius: 50px;
  box-shadow: 0px 0px 2px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaaf,
    -8px -8px 15px #ffffff;
  cursor: pointer;
}
.content .text {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-top: 1rem;
}

.input-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 1.25rem;
}
.input-wrapper .input {
  width: 100%;
}
.input-label {
  pointer-events: none !important;
  color: #666666 !important;
}

.content .field {
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 0.6rem;
}

.field input,
.field select {
  height: 100%;
  width: 100%;
  padding-left: 45px;
  font-size: 18px;
  outline: none;
  border: none;
  color: #595959;
  background: #dde1e7;
  border-radius: 25px;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}
.field select option {
  max-width: 100%;
  background: #dde1e7;
  border-radius: 10px;
  padding: 40px 30px;
  box-shadow: -3px -3px 7px #ffffff73, 2px 2px 5px rgba(94, 104, 121, 0.288);
  flex-grow: 1;
}

.field input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.field input:focus ~ label {
  box-shadow: inset 2px 2px 5px #babecc, inset -1px -1px 2px #ffffff73;
}

.field span {
  position: absolute;
  left: 18px;
  /* width: 50px; */
  line-height: 50px;
  color: #595959;
}

.field input:focus ~ label {
  opacity: 0;
}

.icon-button {
  margin-top: 2rem;
  text-align: center;
}
.icon-button button {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 6px;
  padding-bottom: 6px;

  border-radius: 5px;
  line-height: 30px;
  border: none;
  background: #dde1e7;
  box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;
}
.icon-button button.facebook {
  margin-right: 17px;
}
.icon-button button:hover {
  color: #3498db;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #ffffff73;
}
