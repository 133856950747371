.loader-div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.loader{
    width: 10vw;
}

.MuiCircularProgress-root {
    display: inline-block;
    color: black;
    -webkit-animation: animation-61bdi0 1.4s linear infinite;
    animation: animation-61bdi0 1.4s linear infinite;
}