.dashboard-sidebar {
  position: relative;
  width: 16%;
  min-width: 180px;
  margin-top: 40px;
}

.dashboard-sidebar nav {
  margin-top: 1rem;
}
.sidemenu-header {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2rem;
  color: #93782c;
  text-transform: uppercase;
  padding: 0.1rem 0.5rem;
}
.dashboard-sidebar li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size:0.9rem;
  text-transform: capitalize;
}
ul > .menu-items > div .img-Bx {
  margin-right: 10px;
  font-size: 12px;
  width: 35px;
  height: 35px;
  color: #fff;
  line-height: 40px;
  background: linear-gradient(to bottom, #9f8754 0, #72520e 100%);
  border-color: #9f8754 !important;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-sidebar ul > .menu-items>div>div{
      display: flex;
      justify-content:flex-start;
      align-items: center;
}
.dashboard-sidebar ul> .menu-items>a .menu-items2>div{
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
.dashboard-sidebar ul > .menu-items a .menu-items2 .img-Bx {
  margin-right: 10px;
  font-size: 12px;
  width: 35px;
  height: 35px;
  color: #fff;
  line-height: 40px;
  background: linear-gradient(to bottom, #9f8754 0, #72520e 100%);
  border-color: #9f8754 !important;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-sidebar ul > .menu-items .img-Bx img {
  width: 90%;
  height: 90%;
  object-fit: cover;
  background-size: cover;
  filter: invert(100%);
  transition: all 0.3s linear;
}
.dashboard-sidebar ul:first-child > .menu-items a .menu-items2:hover .img-Bx img{
  transform: scale(1.2);
  animation-name:zigzag;
  animation-duration:0.3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: all 0.3s linear;
}
@keyframes zigzag {
 0%{
    rotate : -15deg
 }
 50%{
  rotate : 0deg
 }
 100%{
  rotate : 15deg
 }
}
.dashboard-sidebar ul > .menu-items > div a {
  color: #7c5e1e;
  font-weight: 500;
  font-size: 1.0rem;
  text-transform: uppercase;
}
.dashboard-sidebar ul > .menu-items {
  font-family: "Ubuntu";
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  margin: 0.2rem 0;
}
.dashboard-sidebar ul > .menu-items:hover span {
  color: #68541a;
}
.dashboard-sidebar ul > .menu-items span {
  color: #000;
  font-size:1rem;
}

.dashboard-sidebar ul > .menu-items > div {
  color: #7c5e1e;
  font-weight: 500;
  font-size: 1.05rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  cursor: pointer;
  border-radius: 0px 100px 100px 0px;
  background: rgb(249 247 243);
  box-shadow: 0px 1px 0px 0px #ddd;
}
.dashboard-sidebar ul > .menu-items > div:hover {
  background: #f1ebda;
}
.dashboard-sidebar ul > .menu-items > a > div:hover{
  background: #f1ebda;
}
.dashboard-sidebar ul > .menu-items > a > div.active{
  background: #f1ebda;
}
.dashboard-sidebar ul > .menu-items > a > div {
  color: #7c5e1e;
  font-weight: 500;
  font-size: 1.05rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.6rem;
  cursor: pointer;
  border-radius: 0px 100px 100px 0px;
  background: rgb(249 247 243);
  box-shadow: 0px 1px 0px 0px #ddd;
}
.dashboard-sidebar ul > .menu-items > div:hover a > div {
  background: #f1ebda;
}
.dashboard-sidebar ul > .menu-items ul {
  background-color: #fff;
  margin: 0.5rem 0;
}
.dashboard-sidebar ul > .menu-items ul li {
  transition: all 0.3s ease;
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid #4949499c;
  margin: 0.15rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s linear;
}
.dashboard-sidebar ul > .menu-items ul li a {
  font-size: 0.85rem;
  font-weight: 600;
  text-transform:uppercase;
}
.dashboard-sidebar ul > .menu-items ul li:hover {
  background: #f1ebda;
}
.dashboard-sidebar ul > .menu-items ul li.active {
  background: #f1ebda;
}
.dashboard-sidebar ul > .menu-items ul li:hover a {
  color: #7c5e1e;
}
.dashboard-sidebar ul > .menu-items ul li:hover a.active {
  color: #7c5e1e;
}
.dashboard-sidebar ul > .menu-items > div svg {
  font-weight: 900;
  width: 1.5rem;
  height: 1.5rem;
  color: #998754;
}
.dashboard-sidebar .logo-imgBx {
  width:55%;
  margin:0.5rem auto;
}
.dashboard-sidebar .logo-imgBx img {
  width: 100%;
  height: auto;
  object-fit: fill;
}

.dashboard-sidebar ul > .menu-items ul {
  background-color: #fff;
  margin: 0.1rem 0;
  max-height: 200px;
  overflow-y: auto;
}
.dashboard-sidebar ul > .menu-items ul::-webkit-scrollbar {
  width:0.3em;
}

.dashboard-sidebar ul > .menu-items ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(159, 135, 84,0.3);
}

.dashboard-sidebar ul > .menu-items ul::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #9f8754 0, #72520e 100%);

}
.dashboard-sidebar.laptopscreen  .user-dp-container.laptop-view{
  display: none;
}
.dashboard-sidebar.laptopscreen .cross-btn{
  display: none;
}
.bottom-navbar{
display: none;
}
@media screen and (max-width:1150px){
  .dashboard-sidebar ul > .menu-items span {
    font-size:1rem;
  }
}
@media screen and (max-width:1025px){
  .dashboard-sidebar.laptopscreen{
         position: absolute;
         top: 0%;
         left: 0%;
         width: 100%;
         background-color: #fff;
         padding:1rem 0;
         z-index:999;
         display: none;
  }  
  .dashboard-sidebar.laptopscreen .logo-imgBx{
    display: none;
  }
  .dashboard-sidebar.laptopscreen  .user-dp-container.mobile-view{
    display:flex;
  }
  .dashboard-sidebar.laptopscreen .cross-btn{
    display:inline-block;
    position: absolute;
    top: 5%;
    right:5%; 
    color: #000;
    font-size: 1rem;
    background-color: transparent;
    border: none;
  }
  .bottom-navbar{
    display: block;
    width: 100%;
    position: fixed !important;
    /* z-index: 900; */
    background: #9f8754;
    color: #fff;
    border-radius: 30px 30px 0px 0px;
    padding: 0.3rem;
    bottom: 0;
  }
  .bottom-navbar >div{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .bottom-navbar>div>div a{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bottom-navbar>div>div a span{
    font-size:0.85rem;
    font-weight:600;
    letter-spacing:0.1rem;
    color: #fff;
  }
  .bottom-navbar>div>div a img{
    width:30px;
    height:30px;
    color: #fff;
  }
  .bottom-navbar .hamburger{ 
    display:flex;  
    background: #fff;
    border-radius: 100px;
    font-size: 19px;
    box-shadow: 0px 0px 5px 0px #ccc inset;
    width: 70px;
    height: 70px;
    padding: 22px 0px 0px;
    margin: -25px auto 0;

  }

}
