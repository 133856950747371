.Payment__modal__wrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(132, 137, 146, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
}

.paymentModal__container {
  width: 50%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.deleteModalIcon {
  background-color: #ffe9ef;
  color: red;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @start */
.paymentModal__top {
  display: flex;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}
.paymentModal__icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.paymentModal__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.paymentModal__bottom {
  width: 100%;
}

.paymentModal__button {
  display: flex;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
  /* align-items: flex-start; */
  gap: 0.75rem;
  /* align-self: stretch; */
  flex-direction: column;
  width: 100%;
}

.btn_cash {
  flex: 1 0 0;
  border-radius: 0.5rem;
  font-weight: bold;
  border: 1px solid #0e81b9;
  background: #0e81b9;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}

.btn_online {
  flex: 1 0 0;
  border-radius: 0.5rem;
  font-weight: bold;
  border: 1px solid #0e81b9;
  background: #0e81b9;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}
.btn__no {
  flex: 1 0 0;
  border-radius: 0.5rem;
  font-weight: bold;
  border: 1px solid #ba1919;
  background: #ba1919;
  display: flex;
  padding: 0.756rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}

.btn__yes {
  flex: 1 0 0;
  border-radius: 0.5rem;
  font-weight: bold;
  border: 1px solid #0e81b9;
  background: #0e81b9;
  display: flex;
  padding: 0.756rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}

.paymentModal__img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}

.paymentModal__img img {
  width: 45%;
  height: 15rem;
  object-fit: cover;
  border-radius: 7px;
}
