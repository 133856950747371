body {
  margin: 0;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --btn-bgColor: #7c5e1e;
}


.btn-bgColor {
  background-color: var(--btn-bgColor) !important;
}


/*FACILITY TREE DROPDOWN STYLE*/
.css-16f7sbh-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 3px !important;
}


/* services modal dropdown  */
.css-1jaewes-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 3px !important;
}