.pdfCard:hover {
    text-decoration: underline;
    color: #9f8754;
    transition: all 0.3s linear;
}



.trainerregistrationh3 h3::before {
    font-family: "Poppins", sans-serif;
    background-color: black;
    color: #f2f2f2;
    padding: 4px 8px;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 8px;
    font-size: 15px;
}

.registration-information h3::before {
    content: "1";
    padding-inline: 10px;
}

.trainer-information h3::before {
    content: "2";
    padding-inline: 10px;
}

.trainer-contact-information h3::before {
    content: "3";
}

.trainer-upload-photo h3::before {
    content: "4";
}

.trainer-upload-documents h3::before {
    content: "5";
}

.trainer-remark h3::before {
    content: "6";
}
.trainer-tandc h3::before {
    content: "7";
}