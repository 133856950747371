@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Poppins&display=swap");

.bg-color {
  background-color: white;
  font-size: 5px;
  padding: 5px;
}

.horseregistrationh3 h3::before {
  font-family: "Poppins", sans-serif;
  background-color: black;
  color: #f2f2f2;
  padding: 4px 8px;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 8px;
  font-size: 15px;
}

.horse-information h3::before {
  content: "1";
  padding-inline: 10px;
}

.horse-fei-registration h3::before {
  content: "2";
}

.horse-owner-information h3::before {
  content: "3";
}

.horse-trainer-information h3::before {
  content: "4";
}

.horse-upload-photo h3::before {
  content: "5";
}

.horse-upload-document h3::before {
  content: "6";
}

.owner-upload-document1 h3::before {
  content: "4";
}

.horse-registration-healthfiles h3::before {
  content: "7";
}

.horse-registration-remark h3::before {
  content: "8";
}

.horse-registration-tandc h3::before {
  content: "9";
}

.owner-registration-remark1 h3::before {
  content: "5";
}

.addbtn {
  float: right;
  color: #7d5f1f;
  border-radius: 50%;
}

.inputparts::before {
  border-left: 2px solid black;
}

.inputparts p {
  margin-top: 8px !important;
}

.horseRegistration p {
  font-size: 12.5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: none !important;
}

.inputfield {
  /* background-color: white; */
  margin-block: 20px;
}

.horseregistrationspan span {
  color: red;
}

label {
  font-size: 12px;
  font-weight: bold;
}

.btn-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 20px;
  gap:"15px"
}
/* 
.MuiInputBase-input-MuiOutlinedInput-input:invalid {
  height: 0.4375em I !important;
}

.MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 4px 7.5px 5px;
  color: #000 !important;
  font-weight: 300px !important;
  font-size: 0.85rem;
} */

