.horse-profile-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* margin: 0px 40px; */
    padding: 0px 0px 0px 60px;
}

.data-image-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 100px;
}

.profile-image-side {
    width: 20.5rem;
    height: 20.5rem;
    display: inline-block;
    border-radius: 50%;
}

.profile-image-container {
    width: 25%;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

}

.profile-image-container img {
    width: 300px;
    height: auto;
    border-radius: 50%;
}

.profile-details-container {
    width: 100%;
    padding: 20px;
}



.profile-details-heading ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
}

.profile-details-heading ul li a {
    text-decoration: none;
}

.profile-details-heading p {
    display: flex;
    align-items: center;
    gap: 7px;
}

.profile-details-detail {
    margin-block: 30px;
    /* background-color: rebeccapurple; */
}

.horse-profile-multiple-values-container {
    /* margin: 40px; */
    padding: 20px;
    height: fit-content;
    z-index: 100;
}

.multiple-values-heading {
    margin-block-end: 10px;
    padding-block: 10px;
    border-bottom: 2px groove #f1ebda;
}

.multiple-values-heading ul {
    display: flex;
    list-style: none;
    gap: 20px;
}

.multiple-values-heading ul li a {
    text-decoration: none;
}

.multiple-values-details {
    height: fit-content;
    padding-block: 20px;
}

.active-link {
    background-color: var(--btn-bgColor);
    color: white;
    font-size: 14.5px;
    font-weight: bold;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.multiple-values-heading ul li :hover {
    background-color: #f1ebda;
    color: black;
    font-size: 14.5px;
    font-weight: bold;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.inactive-link {
    font-size: 14.5px;
    font-weight: bold;
    padding: 10px;
}


.active-link-details {
    color: green;
    font-size: 14.5px;
    font-weight: bold;
}

.inactive-link-details {
    color: gray;
    font-size: 14.5px;
    font-weight: bold;
}



.profile-ditails-single-data-container {
    height: fit-content;
    border-left: 2px solid;
    margin-left: 12px;
    padding: 20px 10px;
    line-height: 25px;
    display: flex;
    justify-content: flex-start;
}

.health-vacc-file {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    flex-wrap: wrap;
}

.profile-ditails-single-data-container ul {
    padding-left: 50px;
}

.profile-common-layout h3::before {
    font-family: "Poppins", sans-serif;
    background-color: black;
    color: #f2f2f2;
    padding: 4px 8px;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 8px;
    font-size: 15px;
}

.horse-info h3::before {
    content: "1";
}

.fei-reg h3::before {
    content: "2";
}

.owner-info h3::before {
    content: "3";
}

.trainer-info h3::before {
    content: "4";
}

.doc-info h3::before {
    content: "4";
}

.doc-mb-info h3::before {
    content: "5";
}

.planner-mb-info h3::before {
    content: "6";
}

.temp-outpass-mb-info h3::before {
    content: "7";
}

.temp-inpass-mb-info h3::before {
    content: "8";
}

.events-mb-info h3::before {
    content: "9";
}

.doc-mb-info {
    display: none;
}

.planner-mb-info {
    display: none;
}

.temp-outpass-mb-info {
    display: none;
}

.temp-inpass-mb-info {
    display: none;
}

.events-mb-info {
    display: none;
}

.profile-image-side-top {
    display: none;
}

.display-property{
    display: block;
}

@media screen and (max-width: 1200px) {
    .horse-profile-container {
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0px;
        padding: 0px;
    }

    .data-image-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
    }


    .profile-ditails-single-data-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        width: 90%;
    }

    .profile-ditails-single-data-container ul {
        padding-left: 10px;
    }
 

    .profile-image-side-div {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-block-end: 20px;
        display: none;
    }

    .profile-image-side-div-top {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-block-end: 20px;
    }


    .profile-image-side-top {
        display: block;
        width: 10.5rem;
        height: 10.5rem;
        display: inline-block;
        border-radius: 50%;
    }

    .multiple-values-heading {
        display: none;
    }

    .doc-info {
        display: none;
    }

    .doc-mb-info {
        display: block;
    }

    .planner-mb-info {
        display: block;
    }

    .temp-outpass-mb-info {
        display: block;
    }

    .temp-inpass-mb-info {
        display: block;
    }

    .events-mb-info {
        display: block;
    }

    .horse-profile-multiple-values-container {
        padding: 0px;
        height: fit-content;
        z-index: 100;
    }

    .multiple-values-details {
        height: fit-content;
        padding-block: 0px;
    }

    .profile-ditails-single-data-container-mb {
        height: fit-content;
        border-left: 2px solid;
        margin-left: 12px;
        padding: 20px 10px;
        line-height: 25px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

  .display-property{
    display: none;
  }

  .welcome-area{
    padding-inline: 10px;
  }
}