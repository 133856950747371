.dashboard-contentbar{
    position: relative;
    width:84%;
   margin-top: 40px;
}
@media only screen and (max-width: 1025px){
    .dashboard-contentbar{
        width:100%;
       
    }
}
